import React from "react";
import { ImageAssetElement } from "../../elements/image-asset.element";
import { classNames } from "../../../helpers/class-names.helper";

export function BlockTestimonialAvatar({ data = {}, assets, color }) {
  const { quote, author, isDark = false, isPrimary = false } = data;

  return (
    <section className="overflow-hidden">
      <div className="relative max-w-screen-xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
        <svg
          className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
          width="784"
          height="404"
          fill="none"
          viewBox="0 0 784 404"
        >
          <defs>
            <pattern
              id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className={classNames([
                  isPrimary && color.text[700],
                  isDark && "text-gray-700",
                  !isPrimary && !isDark && "text-gray-100",
                ])}
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="784"
            height="404"
            fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
          />
        </svg>

        <svg
          className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="56409614-3d62-4985-9a10-7ca758a8f4f0"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className={classNames([
                  isPrimary && color.text[700],
                  isDark && "text-gray-700",
                  !isPrimary && !isDark && "text-gray-100",
                ])}
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)"
          />
        </svg>

        <div className="relative lg:flex lg:items-center">
          <div className="hidden lg:block lg:flex-shrink-0">
            <ImageAssetElement
              assets={assets}
              assetKey={author.portrait}
              isFluid={true}
              objectFit="cover"
              className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
            />
          </div>

          <div className="relative lg:ml-10">
            <svg
              className={classNames([
                "absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 opacity-50",
                isPrimary && color.text[300],
                isDark && "text-gray-300",
                !isPrimary && !isDark && "text-gray-300",
              ])}
              stroke="currentColor"
              fill="none"
              viewBox="0 0 144 144"
            >
              <path
                strokeWidth="2"
                d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
              />
            </svg>
            <blockquote className="relative">
              <div className="text-2xl xl:text-3xl leading-9 xl:leading-snug tracking-wide font-thin font-sans">
                <p
                  className={classNames([
                    isPrimary && color.text[100],
                    isDark && "text-gray-100",
                    !isPrimary && !isDark && "text-gray-900",
                  ])}
                >
                  {quote}
                </p>
              </div>
              <footer className="mt-8">
                <div className="flex">
                  <div className="flex-shrink-0 w-12 lg:hidden">
                    <ImageAssetElement
                      assets={assets}
                      assetKey={author.portrait}
                      isFluid={true}
                      objectFit="cover"
                      className="h-12 w-12 rounded-full"
                    />
                  </div>
                  <div className="ml-4 lg:ml-0">
                    <div
                      className={classNames([
                        "text-base leading-6 font-medium",
                        isPrimary && color.text[100],
                        isDark && "text-gray-100",
                        !isPrimary && !isDark && "text-gray-900",
                      ])}
                    >
                      {author.name}
                    </div>
                    <div
                      className={classNames([
                        "text-base leading-6 font-medium",
                        isPrimary && color.text[300],
                        isDark && "text-gray-400",
                        !isPrimary && !isDark && color.text[600],
                      ])}
                    >
                      {author.position}, {author.company}
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
}

export { BlockTestimonialAvatar as default };
