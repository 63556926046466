import React from "react";
import { ButtonElement } from "./button.element";

export function CtaButtonsElement({
  primary,
  secondary,
  size = "md",
  isPrimary = false,
  isDark = false,
  center = false,
  start = false,
  end = false,
  condensed = false,
  color,
}) {
  const { link, linkTarget, text, external, icon, events, tracking } =
    primary || {};
  const {
    link: secondaryLink,
    linkTarget: secondaryLinkTarget,
    text: secondaryText,
    icon: secondaryIcon,
    external: secondaryExternal,
    events: secondaryEvents,
    tracking: secondaryTracking,
  } = secondary || {};

  const wrapperClass = [
    "flex",
    !condensed && "mt-8",
    center && "justify-center",
    start && "justify-start",
    end && "justify-end",
  ].join(" ");

  return (
    <div className={wrapperClass}>
      {text && (
        <div className="inline-flex rounded-md shadow">
          <ButtonElement
            link={link}
            linkTarget={linkTarget}
            text={text}
            icon={icon}
            size={size}
            external={external}
            isPrimary={true}
            isDark={true}
            isInverted={isDark || isPrimary}
            events={events}
            color={color}
            tracking={tracking}
          />
        </div>
      )}
      {secondaryLink && secondaryText && (
        <div className="ml-3 inline-flex">
          <ButtonElement
            link={secondaryLink}
            linkTarget={secondaryLinkTarget}
            text={secondaryText}
            icon={secondaryIcon}
            size={size}
            external={secondaryExternal}
            isSecondary={true}
            isInverted={isDark || isPrimary}
            events={secondaryEvents}
            color={color}
            tracking={secondaryTracking}
          />
        </div>
      )}
    </div>
  );
}
