import React from "react";
import { MarkdownElement } from "./markdown.element";
import classNames from "classnames";

export function DescriptionElement({
  text,
  className,
  isPrimary = false,
  isDark = false,
  size = "lg",
  color,
}) {
  return (
    <div
      className={classNames({
        [className]: !!className,
        "mt-4 max-w-2xl": true,
        "text-gray-700": !isDark && !isPrimary,
        [color.text[50]]: isPrimary,
        "text-gray-100": isDark,
        "text-xl leading-7": size === "xl",
        "text-lg leading-6": size === "lg",
        "text-base leading-5": size === "md",
        "text-sm leading-4": size === "sm",
        "text-xs leading-3": size === "xs",
      })}
    >
      <MarkdownElement
        isDark={isDark}
        isPrimary={isPrimary}
        size={size}
        color={color}
        proseMode={false}
      >
        {text}
      </MarkdownElement>
    </div>
  );
}
