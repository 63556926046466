import React from "react";

export function VideoAssetElement({ video, poster, assets }) {
  if ([video, poster].some(k => typeof k !== "string")) {
    return null;
  }

  const videoAsset = video && assets && assets[video] ? assets[video] : null;

  if (!videoAsset) {
    return null;
  }

  const imageAsset = poster && assets && assets[poster] ? assets[poster] : null;

  return (
    <video
      className="pointer-events-none w-full h-auto"
      style={{ zIndex: 0 }}
      autoPlay={true}
      muted={true}
      loop={true}
      controls={false}
      poster={imageAsset?.src?.publicURL}
    >
      <source
        src={videoAsset?.src?.publicURL}
        type={`video/${videoAsset?.src?.extension}`}
      />
    </video>
  );
}
