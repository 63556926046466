import React, { Fragment } from "react";

import {
  getFixedImage,
  getImageAsset,
  getPublicUrl,
} from "../../../helpers/assets.helper";
import { IconElement } from "./../icon.element";

import Img from "gatsby-image";
import { CtaButtonsElement } from "../cta.buttons.element";
import { ImageAssetElement } from "../image-asset.element";

const defaultConfig = { reverse: false };

function FeatureIcon({ icon }) {
  return (
    <div>
      <span className="text-xl h-12 w-12 text-white rounded-md flex items-center justify-center bg-pink-500">
        <IconElement icon={icon} />
      </span>
    </div>
  );
}

function FeatureCopyQuote({ body, cite, avatar, assets }) {
  return (
    <blockquote>
      {body && (
        <p className="text-base text-gray-600">&laquo;{body.trim()}&raquo;</p>
      )}
      <div></div>
      <footer className="mt-3">
        <div className="flex items-center space-x-3">
          <div className="flex-shrink-0">
            {avatar && (
              <ImageAssetElement
                assetKey={avatar}
                assets={assets}
                className="h-6 w-6 rounded-full"
              />
            )}
          </div>

          {cite && (
            <div className="text-base font-medium text-gray-700">{cite}</div>
          )}
        </div>
      </footer>
    </blockquote>
  );
}

function FeatureCopy({ feature = {}, color, assets }) {
  const { title, description, cta, quote } = feature;

  return (
    <div>
      <div className="mt-6">
        {title && (
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
            {title}
          </h2>
        )}

        {description && (
          <p className="mt-4 text-lg text-gray-700 whitespace-pre-line">
            {description}
          </p>
        )}

        {cta && (
          <div className="mt-6">
            <CtaButtonsElement
              primary={cta?.primary}
              secondary={cta?.secondary}
              color={color}
              size="md"
            />
          </div>
        )}
      </div>

      {quote && (
        <div className="mt-8 border-t border-gray-200 pt-6">
          <FeatureCopyQuote {...quote} assets={assets} />
        </div>
      )}
    </div>
  );
}

function FeatureImage({ image, assets, className }) {
  const asset = getImageAsset(image, assets);

  const fixed = getFixedImage(asset);
  const publicUrl = !fixed ? getPublicUrl(asset) : null;

  return (
    <Fragment>
      <div className={className}>
        {fixed && (
          <Img
            fixed={fixed}
            alt={asset.alt}
            className="max-h-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
          />
        )}
      </div>
      {/* {fixed && (
        <Fragment>
          <img
            src={fixed.src}
            alt={asset.alt}
            className={className}
            aria-hidden={true}
            width={fixed.width}
            height={fixed.height}
          /> */}
      {/* <picture>
            <source
              type="image/webp"
              src={fixed.srcWebp}
              srcSet={fixed.srcSetWebp}
              sizes="(max-width: 1080px) 100vw, 1080px"
              loading="lazy"
            />
            <source
              src={fixed.src}
              srcSet={fixed.srcSet}
              sizes="(max-width: 1080px) 100vw, 1080px"
              loading="lazy"
            />
          </picture> */}
      {/* </Fragment> */}
      {publicUrl && (
        <img src={publicUrl} alt={asset.alt} className={className} />
      )}
    </Fragment>
  );
}

function FeatureElementReverse({ feature, assets, color }) {
  const { icon, image } = feature || {};

  return (
    <Fragment>
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
        {icon && <FeatureIcon icon={icon} />}
        {feature && (
          <FeatureCopy feature={feature} color={color} assets={assets} />
        )}
      </div>
      <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          {/* <img
            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
            src="https://tailwindui.com/img/component-images/inbox-app-screenshot-2.jpg"
            alt="Customer profile user interface"
          /> */}
          {image && (
            <FeatureImage
              assets={assets}
              image={image}
              className="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}

function FeatureElementStraight({ feature, assets, color }) {
  const { icon, image } = feature || {};

  return (
    <Fragment>
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-1">
        <div>
          {icon && <FeatureIcon icon={icon} />}
          {feature && (
            <FeatureCopy feature={feature} color={color} assets={assets} />
          )}
        </div>
      </div>
      <div className="mt-12 sm:mt-16 lg:mt-0">
        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          {image && (
            <FeatureImage
              assets={assets}
              image={image}
              className="w-full lgx:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}
function FeatureElement({ assets, feature, reverse = false, color }) {
  if (!feature) {
    return null;
  }
  return reverse ? (
    <FeatureElementReverse feature={feature} assets={assets} color={color} />
  ) : (
    <FeatureElementStraight feature={feature} assets={assets} color={color} />
  );
}

export function LandingFeatureAlternateElement({
  config,
  data,
  assets,
  color,
}) {
  const { reverse } = Object.assign({}, defaultConfig, config || {});

  if (!Array.isArray(data)) {
    return null;
  }

  return (
    <div className="relative bg-white pt-16 pb-32 overflow-hidden">
      {data.map((feature, idx) => (
        <div className={idx ? "mt-24" : "relative"} key={feature?.title || idx}>
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <FeatureElement
              assets={assets}
              feature={feature}
              reverse={idx % 2 === (reverse ? 0 : 1)}
              color={color}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
