import React from "react";
import { FeatureSubtitleElement } from "./elements/feature.subtitle.element";
import { FeatureTitleElement } from "./elements/feature.title.element";
import { FeatureDescriptionElement } from "./elements/feature.description.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { classNames } from "../../../helpers/class-names.helper";
import { IconElement } from "../../elements/icon.element";
import { MarkdownElement } from "../../elements/markdown.element";

export function BlockFeatureOffsetList({ data = {}, color }) {
  const {
    title,
    subtitle,
    cta = {},
    description,
    features,
    cols = 4,
    isDark = false,
    isPrimary = false,
  } = data;
  const { primary: primaryCta, secondary: secondaryCta } = cta;

  const colsClassNames = {
    4: "sm:grid sm:grid-cols-4 sm:gap-x-8 sm:gap-y-10 lg:col-span-2",
    3: "sm:grid sm:grid-cols-3 sm:gap-x-8 sm:gap-y-10 lg:col-span-2",
    2: "sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:col-span-2",
    1: "sm:grid sm:grid-cols-1 sm:gap-x-8 sm:gap-y-10 lg:col-span-2",
  };

  const colsClassName =
    colsClassNames?.[cols] ||
    "sm:grid sm:grid-cols-4 sm:gap-x-8 sm:gap-y-10 lg:col-span-2";

  return (
    <div>
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <FeatureSubtitleElement
            title={subtitle}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />
          <FeatureTitleElement
            title={title}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />
          <FeatureDescriptionElement
            text={description}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />

          <div className="mt-10">
            <CtaButtonsElement
              primary={primaryCta}
              secondary={secondaryCta}
              center={false}
              isDark={isDark || isPrimary}
              color={color}
            />
          </div>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          {Array.isArray(features) && (
            <ul
              className={classNames(["space-y-10 sm:space-y-0", colsClassName])}
            >
              {features.map(({ title, prose = false, description, icon }) => {
                return (
                  <li className="flex space-x-6 ml-0 sm:ml-10" key={title}>
                    {icon && (
                      <span
                        className={classNames([
                          "flex-shrink-0 h-6 w-6 text-lg",
                          isPrimary && color.text[200],
                          isDark && "text-gray-200",
                          !isPrimary && !isDark && color.text[500],
                        ])}
                      >
                        <IconElement icon={icon} />
                      </span>
                    )}

                    <div className="space-y-2">
                      {title && (
                        <div
                          className={classNames([
                            "text-lg leading-6 font-medium",
                            isPrimary && "text-white",
                            isDark && "text-gray-100",
                            !isPrimary && !isDark && "text-gray-900",
                          ])}
                        >
                          {title}
                        </div>
                      )}

                      <div className="flex space-x-3 lg:py-0 lg:pb-4">
                        {description && (
                          <div
                            className={classNames([
                              "text-base leading-6",
                              isPrimary && color.text[50],
                              isDark && "text-gray-400",
                              !isPrimary && !isDark && "text-gray-700",
                            ])}
                          >
                            <MarkdownElement
                              isPrimary={isPrimary}
                              isDark={isDark}
                              color={color}
                              proseMode={prose}
                              size="md"
                            >
                              {description}
                            </MarkdownElement>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export { BlockFeatureOffsetList as default };
