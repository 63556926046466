import React from "react";
import { DescriptionElement } from "../../elements/description.element";
import { CtaButtonsElement } from "./../../elements/cta.buttons.element";
import { CtaTitleElement } from "./elements/cta.title.element";

export function BlockCtaLeft({ data, color }) {
  const { title, description, cta, isDark = false } = data;
  const { primary: primaryCta, secondary: secondaryCta } = cta;

  return (
    <div>
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
        <CtaTitleElement title={title} isDark={isDark} color={color} />

        <DescriptionElement
          text={description}
          isInverted={isDark}
          color={color}
        />

        <CtaButtonsElement
          primary={primaryCta}
          secondary={secondaryCta}
          isDark={isDark}
          color={color}
        />
      </div>
    </div>
  );
}

export { BlockCtaLeft as default };
