import React from "react";
import { DescriptionElement } from "../../elements/description.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { CtaTitleElement } from "./elements/cta.title.element";
import Img from "gatsby-image";
import { TitleLabelElement } from "../../elements/title-label.element";
import classNames from "classnames";

export function BlockCtaSplitImage({ data, assets, color }) {
  const {
    title,
    description,
    cta,
    image,
    subtitle,
    isCover = true,
    isPrimary,
    isDark,
    isLeft = false,
    isRight = false,
  } = data;
  const { primary: primaryCta, secondary: secondaryCta } = cta || {};

  const imageAsset = image && assets && assets[image] ? assets[image] : null;

  return (
    <div className="relative">
      <div
        className={classNames({
          "h-56 sm:h-72 md:absolute md:h-full md:w-1/2": true,
          "md:left-0": isRight,
          "md:left-1/2": isLeft,
        })}
      >
        {imageAsset && (
          <Img
            fixed={imageAsset.src.childImageSharp.fixed}
            style={{
              width: "100%",
              height: "100%",
            }}
            imgStyle={{
              width: "100%",
              height: "100%",
              objectFit: isCover ? "cover" : "contain",
            }}
            alt={imageAsset.alt}
          />
        )}
      </div>
      <div
        className={classNames({
          "relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16": true,
        })}
      >
        <div
          className={classNames({
            "md:w-1/2": true,
            "md:mr-auto md:pr-10": isLeft,
            "md:ml-auto md:pl-10": isRight,
          })}
        >
          {subtitle && (
            <TitleLabelElement
              text={subtitle}
              isPrimary={isPrimary}
              isDark={isDark}
              color={color}
            />
          )}

          <CtaTitleElement
            title={title}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />

          <DescriptionElement
            text={description}
            isPrimary={isDark}
            isDark={isPrimary}
            color={color}
          />

          {(primaryCta || secondaryCta) && (
            <CtaButtonsElement
              primary={primaryCta}
              secondary={secondaryCta}
              start={true}
              isPrimary={isPrimary}
              isDark={isDark}
              color={color}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export { BlockCtaSplitImage as default };
