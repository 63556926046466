import { createElement } from "react";
import classNames from "classnames";

function getCoreProps(props) {
  const source = props["data-sourcepos"];
  /* istanbul ignore next - nodes from plugins w/o position */
  return source ? { "data-sourcepos": source } : {};
}

export function markdownRenderers({ color, isDark, isPrimary, proseMode }) {
  return {
    heading(props) {
      const textSizeClass = [
        "text-5xl",
        "text-4xl",
        "text-3xl",
        "text-2xl",
        "text-xl",
        "text-lg",
      ][props.level - 1];

      return createElement(
        `h${props.level}`,
        {
          ...getCoreProps(props),
          className: !proseMode
            ? `block ${textSizeClass} font-bold leading-loose`
            : null,
        },
        props.children
      );
    },
    link(props) {
      return createElement(
        "a",
        {
          ...getCoreProps(props),
          href: props.href,
          className: classNames({
            "font-bold transition duration-150": !proseMode,
            [`${color.text[400]} hover:${color.text[300]}`]:
              isPrimary || isDark,
            [`${color.text[500]} hover:${color.text[700]}`]:
              !isPrimary && !isDark,
          }),
        },
        props.children
      );
    },
    strong(props) {
      return createElement(
        `strong`,
        {
          ...getCoreProps(props),
          className: !proseMode ? `font-bold` : null,
        },
        props.children
      );
    },
  };
}
