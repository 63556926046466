import React from "react";
import { DescriptionElement } from "../../elements/description.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { CtaTitleElement } from "./elements/cta.title.element";

export function BlockCtaCenter({ data, color }) {
  const { title, description, cta, isPrimary, isDark } = data;
  const { primary: primaryCta, secondary: secondaryCta } = cta;

  return (
    <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
      <CtaTitleElement title={title} isDark={isDark || isPrimary} color={color} />

      {description && (
        <DescriptionElement
          text={description}
          isPrimary={isPrimary}
          isDark={isDark}
          color={color}
        />
      )}

      <CtaButtonsElement
        primary={primaryCta}
        secondary={secondaryCta}
        center={true}
        isDark={isDark || isPrimary}
        color={color}
      />
    </div>
  );
}

export { BlockCtaCenter as default };
