import React, { Fragment } from "react";
import { NavbarTopCenteredElement } from "../../elements/navbar/navbar.top.centered.element";
import { HeroTitleElement } from "./elements/hero.title.element";
import { HeroLeadElement } from "./elements/hero.lead.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { TitleLabelElement } from "../../elements/title-label.element";
import classNames from "classnames";

export function BlockHeroDefault({ data, assets, color }) {
  const {
    title,
    lead,
    navigation = {},
    isDark = false,
    isPrimary = false,
    cta = {},
    isLeft = false,
    subtitle,
  } = data;

  const { primary: primaryCta = null, secondary: secondaryCta = null } = cta;

  return (
    <Fragment>
      <div className="relative overflow-hidden">
        <div className="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
          <div className="max-w-screen-xl mx-auto px-4 py-0 sm:px-6 -mt-6">
            <NavbarTopCenteredElement
              data={navigation}
              assets={assets}
              color={color}
            />
          </div>

          <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
            <div
              className={classNames({
                "items-start text-left": isLeft,
                "text-center items-center": !isLeft,
                "flex flex-col": true,
              })}
            >
              {subtitle && (
                <TitleLabelElement
                  text={subtitle}
                  isDark={isDark}
                  isPrimary={isPrimary}
                  color={color}
                />
              )}

              <HeroTitleElement
                title={title}
                isDark={isDark}
                isPrimary={isPrimary}
                color={color}
                className="max-w-screen-lg"
              />

              <HeroLeadElement
                text={lead}
                isDark={isDark}
                isPrimary={isPrimary}
                color={color}
              />

              {cta && (
                <div className="flex items-center justify-start sm:justify-center lg:justify-start">
                  <CtaButtonsElement
                    primary={primaryCta}
                    secondary={secondaryCta}
                    centered={false}
                    isDark={isDark}
                    size="lg"
                    isPrimary={isPrimary}
                    color={color}
                  />
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
}

export { BlockHeroDefault as default };
