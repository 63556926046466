import React, { useEffect, useState } from "react";
import { ImageAssetElement } from "../../elements/image-asset.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { EmbeddedLinkElement } from "../../elements/embedded-link.element";
import { BlockFormNested } from "../form/block.form.nested";
import { VideoAssetElement } from "../../elements/video-asset.element";
import { ChartElement } from "../../elements/chart.element";
import classNames from "classnames";

function CurrentFeatureScreen({ feature, assets, siteConfig, color, next }) {
  const { title, embeddedLink, video, form, image, chart } = feature;
  return (
    <div
      key={title}
      onClick={() => next()}
      className={`rounded-lg shadow-2xl overflow-hidden mt-12 -mb-10 sm:-mb-12 lg:-mb-8 flex items-end justify-center max-height-screen-h transition duration-200`}
    >
      {embeddedLink && <EmbeddedLinkElement url={embeddedLink} />}

      {video && <VideoAssetElement video={video} assets={assets} poster={image} />}

      {form && (
        <BlockFormNested
          data={form}
          assets={assets}
          color={color}
          siteConfig={siteConfig}
        />
      )}

      {image && !video && (
        <ImageAssetElement
          assets={assets}
          isFixed={true}
          assetKey={image}
          className="w-full shadow-xl lg:shadow-2xl"
        />
      )}

      {chart && <ChartElement chart={chart} color={color} />}
    </div>
  );
}

export function BlockFeatureReel({ data = {}, assets, color, siteConfig }) {
  const {
    title,
    subtitle,
    cta = {},
    description,
    features,
    isDark,
    isPrimary,
    startIndex = 0,
    wheelDelay = null,
  } = data;

  const { primary: primaryCta, secondary: secondaryCta } = cta;

  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(startIndex);

  useEffect(() => {
    if (typeof wheelDelay === "number" && wheelDelay >= 1000) {
      const timer = window.setInterval(() => next(), wheelDelay);
      return () => clearInterval(timer);
    }
  }, []);

  const currentFeature =
    Array.isArray(features) && features[currentFeatureIndex];

  function switchCurrent(to) {
    setCurrentFeatureIndex(to);
  }

  function next() {
    setCurrentFeatureIndex(currentIdx => (currentIdx + 1) % features.length);
  }

  return (
    <div className="relative overflow-hidden py-12 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <div>
          {subtitle && (
            <h2
              className={`text-base font-semibold tracking-wider ${color.text[600]} uppercase`}
            >
              {subtitle}
            </h2>
          )}
          {title && (
            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl lg:max-w-xl lg:mx-auto">
              {title}
            </p>
          )}
          {description && (
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-700 whitespace-pre-line">
              {description}
            </p>
          )}
        </div>
        {currentFeature && (
            <CurrentFeatureScreen
              feature={currentFeature}
              assets={assets}
              siteConfig={siteConfig}
              color={color}
              next={() => next()}
            />
        )}
      </div>

      <div className="py-12 xbg-gray-50 md:pt-14">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
          <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-10">
            {Array.isArray(features) &&
              features.map(({ title, description }, idx) => {
                const isActive = currentFeatureIndex === idx;

                return (
                  <div
                    key={title}
                    onClick={() => switchCurrent(idx)}
                    className={classNames([
                      "relative transition duration-100 cursor-pointer hover:opacity-100 mt-8 md:mt-0",
                      {
                        "md:opacity-75": !isActive,
                      },
                    ])}
                  >
                    <div
                      className={classNames(
                        ["absolute transform transition duration-200 z-0"],
                        {
                          "scale-x-100 bg-gray-100 opacity-100 shadow-lg": isActive,
                          "scale-x-0 opacity-0": !isActive,
                        }
                      )}
                      style={{
                        top: "-2rem",
                        right: "-1.5rem",
                        bottom: "-2rem",
                        left: "-1.5rem",
                      }}
                    />

                    <dt className="relative z-10">
                      <p
                        className={classNames(
                          [
                            "text-lg md:text-xl lg:text-2xl leading-6 font-extrabold transition duration-200 text-gray-900",
                          ],
                        )}
                      >
                        {title}
                      </p>
                    </dt>
                    <dd
                      className={classNames(
                        [
                          "relative z-10 mt-2 text-base prose md:prose-base whitespace-pre-line transition duration-200",
                        ],
                        {
                          "text-gray-800": isActive,
                          "text-gray-700": !isActive,
                        }
                      )}
                    >
                      {description}
                    </dd>
                  </div>
                );
              })}
          </dl>
        </div>
      </div>

      <div className="mt-4 relative z-10">
        <CtaButtonsElement
          primary={primaryCta}
          secondary={secondaryCta}
          center={true}
          isDark={isDark || isPrimary}
          color={color}
        />
      </div>
    </div>
  );
}

export { BlockFeatureReel as default };
