import React, { Component } from "react";
import classNames from "classnames";
import { createBaseEvent } from "../../../../events/event-creator";
import { conversionTrigger, fireEvent } from "../../../../events/events.helper";
import { FormMagicBuilder } from "./form-magic.builder";
import { IconElement } from "../../../elements/icon.element";
import { delay } from "./../../../../helpers/gui.helper";
import { CtaButtonsElement } from "../../../elements/cta.buttons.element";

const FORM_PROGRESS_STATE = {
    LOADING: "LOADING",
    READY: "READY",
    STARTED: "STARTED",
    VALIDATING: "VALIDATING",
    SENDING: "SENDING",
    FAILED: "FAILED",
    INVALID: "INVALID",
    SUCCESS: "SUCCESS",
};

function InFormOverlay({ visible, children, className }) {
    return (
        <div className={classNames("inset-0 z-10 transition duration-500 flex items-center justify-center absolute", className, {
            'opacity-100': visible,
            'opacity-0 pointer-events-none': !visible,
        })}>
            {children}
        </div>
    );
}

function OverlayMessage({ title, message, icon, cta, visible, color }) {
    return (
        <InFormOverlay className="bg-white text-gray-800" visible={visible}>
            <div className="">
                {icon && (
                    <span className="flex justify-center items-center mx-auto w-24 h-24 bg-white border-4 border-pink-400 text-pink-500 text-6xl rounded-full"><IconElement icon={icon} /></span>
                )}

                <div className="py-8 px-8 sm:px-12">
                    {title && (<strong className="block text-lg sm:text-xl font-bold mb-2">{title}</strong>)}

                    {message && (<p className="text-lg sm:text-xl font-semibold">{message}</p>)}

                    <div className="flex items-center justify-center">
                        {cta && (<CtaButtonsElement primary={cta?.primary} secondary={cta?.secondary} color={color} />)}
                    </div>
                </div>
            </div>
        </InFormOverlay>
    );
}

export class FormMagicElement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            progress: FORM_PROGRESS_STATE.LOADING,
            errors: null,
        };

        this.config = props?.config || null;
    }

    componentDidMount() {
        window.setTimeout(() => {
            this.setProgress(FORM_PROGRESS_STATE.READY);
        }, 500);
    }

    redirectPage({ url }) {
        window.location.href = url;
    }

    conversionSuccess() {
        if (this.props?.nested && this.props?.conversion) {
            conversionTrigger(this.props.conversion);
        }

        if (!this.props?.nested) {
            fireEvent(
                createBaseEvent("modal", "conversion", {
                    modalKey: this.props?.modalKey,
                })
            );
        }
    }

    getFormTarget() {
        return [
            this.props.baseConfig?.baseUrl,
            "form/v1/send",
            this.config?.formId,
        ].join("/"); // todo: load base path from siteConfig + env-var
    }

    setProgress(progress) {
        this.setState({
            ...this.state,
            progress,
        });
    }

    setValidationErrors(errors) {
        this.setState({
            ...this.state,
            errors,
        });
    }

    async handleSuccess({ status, message, errors, redirect }) {
        this.setProgress(FORM_PROGRESS_STATE.SUCCESS);
        this.conversionSuccess();

        if (redirect) {
            this.redirectPage(redirect);
        }
    }

    async handleValidationIssues({ errors }) {
        this.setProgress(FORM_PROGRESS_STATE.INVALID);
        this.setValidationErrors(errors);
    }

    async handleFormNotFound({ status, message, errors }) {
        this.setProgress(FORM_PROGRESS_STATE.FAILED);
    }

    async handleServerIssues({ status, message, errors }) {
        this.setProgress(FORM_PROGRESS_STATE.FAILED);
    }

    async handleResponse(response) {
        const { status } = response;

        switch (status) {
            case 200:
                return this.handleSuccess(response);
            case 400:
                return this.handleValidationIssues(response);
            case 404:
                return this.handleFormNotFound(response);
            case 500:
            case 502:
                return this.handleServerIssues(response);
            default:
                return this.handleServerIssues(response);
        }
    }

    async submitForm(event) {
        event.preventDefault();

        this.setState({
            ...this.state,
            progress: FORM_PROGRESS_STATE.SENDING,
            errors: null,
        });

        await delay(500);

        const { target } = event;
        const formData = new FormData(target);

        const message = {};
        for (const [key, value] of formData.entries()) {
            message[key] = value;
        }

        try {
            this.setProgress(FORM_PROGRESS_STATE.VALIDATING);
            const result = await fetch(target.getAttribute('target'), {
                method: target.getAttribute('method'),
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({ message }),
            });

            await this.handleResponse(await result.json());
        } catch (err) {
            this.setProgress(FORM_PROGRESS_STATE.FAILED);
        }
    }

    isLoadingState(state) {
        return [
            FORM_PROGRESS_STATE.LOADING,
        ].includes(state);
    }

    render() {
        const { layout, items, success, failed } = this.config;
        const formTarget = this.getFormTarget();
        const submitLabel = this.config?.submit;
        const { progress, errors } = this.state;

        return (
            <div className="relative">
                <InFormOverlay className="bg-white text-gray-400" visible={this.isLoadingState(progress)}>
                    <div className="w-16 h-16 flex justify-center items-center text-6xl animate-spin ease-in-out duration-700">
                        <IconElement icon="spinnerThird" />
                    </div>
                </InFormOverlay>

                {success && (<OverlayMessage className="bg-white text-gray-800" visible={progress === FORM_PROGRESS_STATE.SUCCESS} {...success} color={this.props.color} />)}
                {failed && (<OverlayMessage className="bg-white text-gray-800" visible={progress === FORM_PROGRESS_STATE.FAILED} {...failed} color={this.props.color} />)}

                <div className={classNames("px-4 py-8 sm:px-6 transition duration-500", {
                    "opacity-0 pointer-events-none": [FORM_PROGRESS_STATE.SUCCESS, FORM_PROGRESS_STATE.FAILED].includes(progress),
                    "opacity-100": ![FORM_PROGRESS_STATE.SUCCESS, FORM_PROGRESS_STATE.FAILED].includes(progress)
                })}>
                    <form
                        target={formTarget}
                        method="POST"
                        onSubmit={(e) => this.submitForm(e)}
                    >
                        <FormMagicBuilder layout={layout} items={items} errors={errors} />

                        {submitLabel && (
                            <button
                                className={
                                    classNames(
                                        "mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-semibold text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 transition duraton-500",
                                        {
                                            "bg-pink-600 hover:bg-pink-700 focus:ring-pink-500": ![FORM_PROGRESS_STATE.VALIDATING, FORM_PROGRESS_STATE.SENDING].includes(progress),
                                            "bg-pink-400 hover:bg-pink-400 focus:ring-transparent cursor-default": [FORM_PROGRESS_STATE.VALIDATING, FORM_PROGRESS_STATE.SENDING].includes(progress),
                                        }
                                    )
                                }
                                type="submit"
                            >

                                <span className={
                                    classNames(
                                        "inline-flex justify-center transition items-center mr-1 animate-spin duration-200",
                                        {
                                            "opacity-100 h-6 w-6": [FORM_PROGRESS_STATE.VALIDATING, FORM_PROGRESS_STATE.SENDING].includes(progress),
                                            "opacity-0 h-0 w-0": ![FORM_PROGRESS_STATE.VALIDATING, FORM_PROGRESS_STATE.SENDING].includes(progress),
                                        }
                                    )
                                }>
                                    <IconElement icon="spinnerThird" />
                                </span>

                                <span>
                                    {submitLabel}
                                </span>
                            </button>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}
