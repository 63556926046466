import React, { Fragment } from "react";
import YouTube from "react-youtube";
import { ImageAssetElement } from "./image-asset.element";

export function VideoElement({ data, assets, color }) {
  const { preview, youtubeId, label } = data;

  const youtubeOptions = {
    playerVars: {
      autoplay: 0,
      loop: 1,
      // list: "user_uploads",
      modestbranding: 1,
      rel: 0,
      controls: 0,
    },
  };

  return (
    <Fragment>
      <div className="flex items-center justify-center flex-wrap w-full relative">
        <div className="w-4/5 relative z-20">
          <YouTube
            opts={youtubeOptions}
            videoId={youtubeId}
            containerClassName={"youtubeContainer"}
          />
        </div>

        <button
          type="button"
          className="hidden relative z-10 cursor-pointer block w-3/4 bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <span className="sr-only">{label}</span>

          <ImageAssetElement
            className="w-full"
            isFluid={true}
            assetKey={preview}
            assets={assets}
          />
          <div
            className="absolute inset-0 w-full h-full flex items-center justify-center"
            aria-hidden="true"
          >
            <svg
              className={`h-20 w-20 cursor-pointer ${color.text[500]} hover:${color.text[600]}`}
              fill="currentColor"
              viewBox="0 0 84 84"
              style={{
                filter: "drop-shadow(2px, 2px, 1px, rgba(0, 0, 0, 0.5))",
              }}
            >
              <circle
                opacity="0.9"
                cx="42"
                cy="42"
                r="42"
                fill="currentColor"
                className={`${color.text[50]} hover:${color.text[200]} transition duration-200`}
              />
              <path
                d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z"
                className="pointer-events-none transition duration-200"
              />
            </svg>
          </div>
        </button>
      </div>
    </Fragment>
  );
}
