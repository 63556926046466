import React from "react";
import Img from "gatsby-image";

export function BlockImageUnpadded({ data, assets }) {
  const { image } = data;
  const imageAsset = image && assets && assets[image] ? assets[image] : null;

  return (
    <div className="relative overflow-hidden max-w-screen-xl m-auto">
      <Img fluid={imageAsset.src.childImageSharp.fluid} className="w-full" />
    </div>
  );
}

export { BlockImageUnpadded as default };
