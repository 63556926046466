import React from "react";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { IconElement } from "../../elements/icon.element";

export function BlockBannerFloating({ data, color }) {
  const { text, icon, cta = {} } = data;

  const { primary: primaryCta = null, secondary: secondaryCta = null } = cta;

  return (
    <div className="fixed z-20 bottom-0 inset-x-0 pb-2 sm:pb-5">
      <div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className={`p-2 rounded-lg ${color.bg[600]} shadow-lg sm:p-3`}>
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              {icon && (
                <span
                  className={`flex p-2 text-xl rounded-lg ${color.bg[800]}`}
                >
                  <IconElement icon={icon} />
                </span>
              )}
              <p className="ml-3 font-medium text-white truncate">
                <span className="md:inline">{text}</span>
              </p>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <div>
                <CtaButtonsElement
                  condensed="true"
                  size="sm"
                  primary={primaryCta}
                  secondary={secondaryCta}
                  center={false}
                  isDark={true}
                  color={color}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { BlockBannerFloating as default };
