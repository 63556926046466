import React, { Component, Fragment } from "react";
import { ButtonElement } from "../../elements/button.element";
import { FORM_PROVIDER_COMPONENT_MAP } from "./form-provider-component.map";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { postman } from "../../../postman";
import { filter } from "rxjs/operators";
import { conversionTrigger } from "../../../events/events.helper";

export class BlockFormModal extends Component {
  #letterbox;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.watchDog = this.watchDog.bind(this);
  }

  getProviderComponent() {
    return FORM_PROVIDER_COMPONENT_MAP[this.props?.data?.provider] || null;
  }

  componentDidMount() {
    if (!this.#letterbox) {
      this.#letterbox = postman
        .pipe(
          filter(
            ({ type, data }) =>
              type === "modal" && data?.modalKey === this.props?.block?.key
          )
        )
        .subscribe(eventData => this.watchDog(eventData));
    }
  }

  componentWillUnmount() {
    this.#letterbox.unsubscribe();
  }

  watchDog(eventData) {
    switch (eventData?.action) {
      case "open":
      case "close":
        return this.setState({ open: eventData?.action === "open" });
      case "conversion":
        return conversionTrigger(this.props?.data?.conversion);
      default:
        return;
    }
  }

  render() {
    const data = this.props?.data || {};

    if (data && typeof data !== "object") {
      return null;
    }

    const { config, close, title } = data;
    const ProviderComponent = this.getProviderComponent();
    const isOpen = this.state.open;
    const providerBaseConfig =
      this.props?.siteConfig?.formProviderSettings?.[
      this.props?.data?.provider
      ] || {};

    return (
      <Fragment>
        {ProviderComponent && (
          <div
            className={classNames({
              "fixed z-50 inset-0 overflow-y-auto": true,
              "pointer-events-none": !isOpen,
            })}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition
                className="fixed inset-0 transition-opacity"
                show={isOpen}
                enter="transition ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  aria-hidden={true}
                  className="absolute inset-0 bg-gray-500 opacity-75"
                  data-tracking-action-label="rescue.success"
                  data-tracking-action-value="backdrop"
                  data-tracking-action-position="action.form"
                  onClick={() => this.setState({ open: false })}
                ></div>
              </Transition>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
              &#8203;
              <Transition
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-sm md:max-w-md lg:max-w-lg sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
                show={isOpen}
                enter="transition ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="transition ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                {title && (
                  <strong className="text-xl leading-6 rounded mb-5 mb-5 block font-semibold text-gray-900">
                    {title}
                  </strong>
                )}

                <ProviderComponent
                  config={config}
                  modalKey={this.props?.block?.key}
                  baseConfig={providerBaseConfig}
                  color={this.props?.color}
                />

                <div className="py-3 -mb-4 -mx-4 sm:-mb-6 sm:-mx-6 sm:mt-2 flex justify-center bg-gray-100 rounded-b-lg">
                  <ButtonElement
                    text={close && close.label ? close.label : "close"}
                    icon={
                      close && close.icon ? close.icon : "arrowAltCircleLeft"
                    }
                    size="xs"
                    isCharmless={true}
                    events={[
                      {
                        trigger: "click",
                        type: "modal",
                        action: "close",
                        data: { modalKey: this.props?.block?.key },
                      },
                    ]}
                    tracking={{
                      actionLabel: "rescue",
                      actionValue: close?.label || "close",
                      actionPosition: "action.form",
                    }}
                    color={this.props.color}
                  />
                </div>
              </Transition>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
