import React, { Fragment } from "react";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { NavbarTopCenteredElement } from "../../elements/navbar/navbar.top.centered.element";
import { TitleLabelElement } from "../../elements/title-label.element";
import { BlockFormNested } from "../form/block.form.nested";
import { HeroLeadElement } from "./elements/hero.lead.element";
import { HeroTitleElement } from "./elements/hero.title.element";

export function BlockHeroForm({ data, assets, color, siteConfig }) {
  const {
    subtitle = null,
    title,
    form,
    lead,
    navigation = {},
    isDark = false,
    isPrimary = false,
    cta = {},
  } = data;

  const { primary: primaryCta = null, secondary: secondaryCta = null } = cta;

  return (
    <Fragment>
      <div className="relative overflow-hidden from-transparent to-gray-50 bg-gradient-to-b">
        <NavbarTopCenteredElement
          data={navigation}
          isDark={isDark}
          isPrimary={isPrimary}
          assets={assets}
          color={color}
        />

        <div className="relative pt-6 pb-12 sm:pb-32">
          <main className="mt-8 sm:mt-16 md:mt-20 lg:mt-24">
            <div className="mx-auto max-w-screen-xl">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                  {/* <a
                      href="/"
                      className="inline-flex items-center text-white bg-gray-900 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                    >
                      <span
                        className={`px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide ${color.bg[500]} rounded-full`}
                      >
                        We're hiring
                      </span>
                      <span className="ml-4 text-sm leading-5">
                        Visit our careers page
                      </span>

                      <svg
                        className="ml-2 w-5 h-5 text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a> */}
                  <div>
                    {subtitle && (
                      <TitleLabelElement
                        text={subtitle}
                        isDark={isDark}
                        isPrimary={isPrimary}
                        color={color}
                      />
                    )}
                    <HeroTitleElement
                      title={title}
                      isDark={isDark}
                      isPrimary={isPrimary}
                      color={color}
                    />
                    <HeroLeadElement
                      text={lead}
                      isDark={isDark}
                      isPrimary={isPrimary}
                      color={color}
                    />

                    {cta && (
                      <div className="flex items-center justify-start sm:justify-center lg:justify-start">
                        <CtaButtonsElement
                          primary={primaryCta}
                          secondary={secondaryCta}
                          centered={false}
                          isDark={isDark}
                          size="lg"
                          isPrimary={isPrimary}
                          color={color}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6">
                  {form && (
                    <div className="bg-white shadow-xl sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                      <BlockFormNested
                        data={form}
                        assets={assets}
                        color={color}
                        siteConfig={siteConfig}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
}

export { BlockHeroForm as default };
