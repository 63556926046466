import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { ImageAssetElement } from "../../elements/image-asset.element";

function TestimonialEntry({ testimonial, assets, color }) {
  const { quote, author } = testimonial || {};

  if (!quote) {
    return null;
  }

  const { logo, portrait, name, position, company } = author || {};

  return (
    <div className="relative">
      {logo && (
        <ImageAssetElement
          assets={assets}
          assetKey={logo}
          className="mx-auto h-8"
        />
      )}

      <blockquote className="mt-10">
        <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-semibold text-gray-900">
          {quote && <p>&ldquo;{quote.trim()}&rdquo;</p>}
        </div>
        <footer className="mt-8">
          <div className="md:flex md:items-center md:justify-center">
            <div className="md:flex-shrink-0">
              {portrait && (
                <span className="mx-auto flex justify-center items-center w-10 h-10 overflow-hidden rounded-full">
                  <ImageAssetElement
                    assets={assets}
                    assetKey={portrait}
                    className="h-10 max-w-screen-sm"
                  />
                </span>
              )}
            </div>
            <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
              {name && (
                <div className="text-base font-medium text-gray-900">
                  {name}
                </div>
              )}

              <svg
                className={`hidden md:block mx-1 h-5 w-5 ${color.text[500]}`}
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M11 0h3L9 20H6l5-20z" />
              </svg>

              <div className="text-base font-medium text-gray-700">
                {position}, {company}
              </div>
            </div>
          </div>
        </footer>
      </blockquote>
    </div>
  );
}

export function BlockTestimonialWheel({ data, assets, color }) {
  const { testimonials } = data || {};

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  useEffect(() => {
    const timer = window.setInterval(() => next(), 7000);
    return () => clearInterval(timer);
  }, []);

  if (!Array.isArray(testimonials)) {
    return null;
  }

  function next() {
    setCurrentTestimonialIndex(idx => (idx + 1) % testimonials.length);
  }

  function setTestimonialIndex(idx) {
    setCurrentTestimonialIndex(idx);
  }

  const currentTestimonial = testimonials[currentTestimonialIndex];

  return (
    <section className="py-12 md:py-20 lg:py-24">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {
          <TestimonialEntry
            testimonial={currentTestimonial}
            key={currentTestimonial.quote}
            assets={assets}
            color={color}
          />
        }
      </div>

      <div className="mt-4">
        <ul className="flex justify-center items-center gap-2">
          {testimonials.map((t, idx) => (
            <ol key={t.quote} onClick={() => setTestimonialIndex(idx)}>
              <span
                className={classNames(
                  [
                    `inline-block h-2 w-2 rounded-full transition-all duration-200`,
                  ],
                  {
                    [`${color.bg[500]}`]: idx === currentTestimonialIndex,
                    [`bg-gray-400 hover:${color.bg[400]} cursor-pointer`]:
                      idx !== currentTestimonialIndex,
                  }
                )}
              ></span>
            </ol>
          ))}
        </ul>
      </div>
    </section>
  );
}

export { BlockTestimonialWheel as default };
