import React from "react";
import { classNames } from "../../../../helpers/class-names.helper";

export function FeatureSubtitleElement({
  title,
  isPrimary = false,
  isDark = false,
  color,
}) {
  return (
    <p
      className={classNames([
        "text-base leading-6 font-semibold tracking-wide uppercase mb-2",
        isPrimary && color.text[50],
        isDark && "text-gray-100",
        !isPrimary && !isDark && color.text[600],
      ])}
    >
      {title}
    </p>
  );
}
