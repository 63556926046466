import React from "react";
import { classNames } from "../../../../helpers/class-names.helper";
import { MarkdownElement } from "../../../elements/markdown.element";

export function FeatureDescriptionElement({
  text,
  isPrimary = false,
  isDark = false,
  color,
}) {
  return (
    <div
      className={classNames([
        "mt-4 text-lg leading-7",
        isPrimary && color.text[50],
        isDark && "text-gray-300",
        !isPrimary && !isDark && "text-gray-700",
      ])}
    >
      <MarkdownElement
        isPrimary={isPrimary}
        isDark={isDark}
        color={color}
        proseMode={false}
        size="lg"
      >
        {text}
      </MarkdownElement>
    </div>
  );
}
