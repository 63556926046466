import React, { Fragment } from "react";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { ImageAssetElement } from "../../elements/image-asset.element";
import { NavbarTopCenteredElement } from "../../elements/navbar/navbar.top.centered.element";
import { HeroLeadElement } from "./elements/hero.lead.element";
import { HeroTitleElement } from "./elements/hero.title.element";

export function BlockHeroImage({ data, assets, color }) {
  const {
    title,
    lead,
    image,
    navigation = {},
    isDark = false,
    isPrimary = false,
    cta = {},
    trust,
  } = data;

  const { primary: primaryCta = null, secondary: secondaryCta = null } = cta;

  return (
    <Fragment>
      <div>
        <div className="relative overflow-hidden">
          <div className="block absolute inset-y-0 h-full w-full">
            <div className="relative h-full">
              <svg
                className="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full"
                width="404"
                height="784"
                fill="none"
                viewBox="0 0 404 784"
              >
                <defs>
                  <pattern
                    id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="784"
                  fill="url(#ad9a0a02-b58e-4a1d-8c36-1b649889af63)"
                />
              </svg>
              <svg
                className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
                width="404"
                height="784"
                fill="none"
                viewBox="0 0 404 784"
              >
                <defs>
                  <pattern
                    id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="784"
                  fill="url(#478e97d6-90df-4a89-8d63-30fdbb3c7e57)"
                />
              </svg>
            </div>
          </div>

          <div className="relative pt-0 pb-12 lg:pb-20">
            <NavbarTopCenteredElement
              data={navigation}
              isDark={isDark}
              isPrimary={isPrimary}
              assets={assets}
              color={color}
            />

            <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:px-6 md:mt-16 lg:mt-20">
              <div className="text-center">
                <div className="flex justify-center max-w-4xl m-auto">
                  <HeroTitleElement
                    title={title}
                    isDark={isDark}
                    isPrimary={isPrimary}
                    color={color}
                  />
                </div>

                <div className="flex justify-center">
                  <HeroLeadElement
                    text={lead}
                    isDark={isDark}
                    isPrimary={isPrimary}
                    color={color}
                  />
                </div>
                <div className="flex justify-center">
                  {cta && (
                    <div className="flex items-center justify-start sm:justify-center lg:justify-start">
                      <CtaButtonsElement
                        primary={primaryCta}
                        secondary={secondaryCta}
                        centered={false}
                        isDark={isDark}
                        size="lg"
                        isPrimary={isPrimary}
                        color={color}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="absolute inset-0 flex flex-col">
              <div className="flex-1"></div>
              <div className="flex-1 w-full bg-gray-100"></div>
            </div>
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
              <ImageAssetElement
                className="shadow-2xl rounded-xl"
                assets={assets}
                assetKey={image}
                isFluid={true}
              />
            </div>
          </div>
        </div>

        {trust && (
          <div className="bg-gray-100">
            <div className="max-w-screen-xl mx-auto pt-16 pb-20 px-4 sm:px-6 md:pb-24 lg:px-8">
              {trust.title && (
                <h3 className="text-center text-gray-700 text-sm font-semibold uppercase tracking-wide">
                  {trust.title}
                </h3>
              )}

              <div className="mt-8 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
                {trust.logos &&
                  trust.logos.map(logo => {
                    return (
                      <div
                        key={logo}
                        className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
                      >
                        <ImageAssetElement
                          assetKey={logo}
                          assets={assets}
                          objectFit="contain"
                          objectPosition="center center"
                          className="h-20 object-center"
                          isFluid={false}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export { BlockHeroImage as default };
