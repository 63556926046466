import React, { useState } from "react";
import { ImageAssetElement } from "../../elements/image-asset.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { EmbeddedLinkElement } from "../../elements/embedded-link.element";
import { BlockFormNested } from "../form/block.form.nested";
import { VideoElement } from "../../elements/video.element";
import { ChartElement } from "../../elements/chart.element";

function EmbeddedVideo({ video, preview, assets }) {
  const imageAsset =
    preview && assets && assets[preview] ? assets[preview] : null;
  const videoAsset = video && assets && assets[video] ? assets[video] : null;

  if (!videoAsset) {
    return null;
  }

  return (
    <video
      className=""
      style={{ zIndex: 0 }}
      autoPlay={true}
      muted={true}
      loop={true}
      controls={false}
      poster={imageAsset?.src?.publicURL}
    >
      <source
        src={videoAsset?.src?.publicURL}
        type={`video/${videoAsset?.src?.extension}`}
      />
    </video>
  );
}

function MediaFile({ media, assets, siteConfig, color }) {
  const { embeddedLink, preview, video, form, image, chart, youtube } =
    media || {};
  return (
    <div className="mt-12 -mb-10 sm:-mb-12 lg:-mb-8 flex items-end justify-center max-height-screen-h transition duration-200">
      {embeddedLink && <EmbeddedLinkElement url={embeddedLink} />}

      {video && (
        <EmbeddedVideo video={video} preview={preview} assets={assets} />
      )}

      {youtube && <VideoElement data={youtube} assets={assets} color={color} />}

      {form && (
        <BlockFormNested
          data={form}
          assets={assets}
          color={color}
          siteConfig={siteConfig}
        />
      )}

      {image && (
        <ImageAssetElement
          assets={assets}
          isFixed={true}
          assetKey={image}
          className="w-full shadow-xl lg:shadow-2xl"
        />
      )}

      {chart && <ChartElement chart={chart} color={color} />}
    </div>
  );
}

export function BlockImageTexted({ data = {}, assets, color, siteConfig }) {
  const {
    title,
    subtitle,
    cta = {},
    description,
    media,
    isDark,
    isPrimary,
  } = data;
  const { primary: primaryCta, secondary: secondaryCta } = cta;

  return (
    <div className="relative overflow-hidden">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl py-12 lg:py-20">
        <div>
          {subtitle && (
            <h2
              className={`text-base font-semibold tracking-wider ${color.text[600]} uppercase`}
            >
              {subtitle}
            </h2>
          )}
          {title && (
            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl lg:max-w-xl lg:mx-auto">
              {title}
            </p>
          )}
          {description && (
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-700 whitespace-pre-line">
              {description}
            </p>
          )}
        </div>
        {media && (
          <MediaFile
            media={media}
            assets={assets}
            siteConfig={siteConfig}
            color={color}
          />
        )}
      </div>

      <div className="mt-4 relative z-10">
        <CtaButtonsElement
          primary={primaryCta}
          secondary={secondaryCta}
          center={true}
          isDark={isDark || isPrimary}
          color={color}
        />
      </div>
    </div>
  );
}

export { BlockImageTexted as default };
