import React from "react";
import { HeroTitleElement } from "./elements/hero.title.element";
import { HeroLeadElement } from "./elements/hero.lead.element";
import { classNames } from "../../../helpers/class-names.helper";
import { NavbarTopCenteredElement } from "../../elements/navbar/navbar.top.centered.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { TitleLabelElement } from "../../elements/title-label.element";
import { ResponsiveRadar } from "@nivo/radar";

const CHART_TYPE_MAP = {
  radar: RadarChartElement,
};

function RadarChartElement({ chart }) {
  const chartKeyed = chart.reduce((a, c) => {
    const delta = c.team - c.benchmark;
    const deltaRel = delta / c.benchmark;

    a[c.title] = { c, delta, deltaRel };
    return a;
  }, {});

  return (
    <div className="block relative w-full" style={{ paddingBottom: "100%" }}>
      <div className="absolute inset-0 bg-white">
        <ResponsiveRadar
          data={chart}
          keys={["team", "benchmark"]}
          indexBy="title"
          maxValue={5}
          margin={{ top: 0, right: 100, bottom: 0, left: 100 }}
          curve="cardinalClosed"
          borderWidth={2}
          borderColor={{ from: "color" }}
          gridLevels={5}
          gridShape="circular"
          gridLabelOffset={23}
          enableDots={true}
          dotSize={5}
          dotBorderWidth={1}
          dotBorderColor={{ from: "color" }}
          enableDotLabel={false}
          dotLabel="value"
          dotLabelYOffset={-12}
          gridLabel={({ id, anchor }) => {
            const { deltaRel } = chartKeyed[id];
            return (
              <g
                transform={`translate(${
                  anchor === "end" ? -40 : anchor === "middle" ? -20 : 0
                }, -20)`}
              >
                <text className="text-sm font-sans fill-current text-gray-700">
                  {id}
                </text>
                <text
                  className={`text-sm font-sans font-bold fill-current ${
                    deltaRel > 0 ? "text-green-500" : "text-red-500"
                  }`}
                  y={29}
                  x={4}
                  style={{
                    fontSize: 22,
                  }}
                >
                  {(deltaRel * 100).toFixed(1)}%
                </text>
              </g>
            );
          }}
          //   colors={{ scheme: "spectral" }}
          colors={({ key }) => {
            if (key === "benchmark") {
              return "rgb(26, 32, 44)";
            }

            // TODO: apply d3-scheme
            return "rgb(233, 30, 99)";
          }}
          fillOpacity={0.0}
          blendMode="multiply"
          animate={true}
          motionConfig="wobbly"
          isInteractive={false}
          tooltipFormat={value => {
            return typeof value === "number" ? value.toFixed(2) : "-";
          }}
          legendFormat={val => "gugus"}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              translateX: -80,
              translateY: 0,
              itemWidth: 80,
              itemHeight: 20,
              itemTextColor: "#ccc",
              symbolSize: 12,
              symbolShape: "circle",
            },
          ]}
        />
      </div>
    </div>
  );
}

function ChartElement({ chart, color }) {
  const { data, type } = chart;
  const chartTypeRenderer = CHART_TYPE_MAP[type];

  if (typeof chartTypeRenderer !== "function") {
    return null;
  }

  return chartTypeRenderer({ chart: data, color });
}

export function BlockHeroChart({ data, assets, color }) {
  const {
    subtitle = null,
    title,
    lead,
    navigation = {},
    isDark = false,
    isPrimary = false,
    cta = {},
    chart = null,
  } = data;

  const { primary: primaryCta = null, secondary: secondaryCta = null } = cta;

  return (
    <div className={classNames(["relative", "overflow-hidden"])}>
      <NavbarTopCenteredElement
        data={navigation}
        isDark={isDark}
        isPrimary={isPrimary}
        assets={assets}
        color={color}
      />

      <div className="relative pt-6 pb-12 sm:pb-32">
        <main className="mt-8 sm:mt-16 md:mt-20 lg:mt-24">
          <div className="mx-auto max-w-screen-xl">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                <div>
                  {subtitle && (
                    <TitleLabelElement
                      text={subtitle}
                      isDark={isDark}
                      isPrimary={isPrimary}
                      color={color}
                    />
                  )}
                  <HeroTitleElement
                    title={title}
                    isDark={isDark}
                    isPrimary={isPrimary}
                    color={color}
                  />
                  <HeroLeadElement
                    text={lead}
                    isDark={isDark}
                    isPrimary={isPrimary}
                    color={color}
                  />

                  {cta && (
                    <div className="flex items-center justify-start sm:justify-center lg:justify-start">
                      <CtaButtonsElement
                        primary={primaryCta}
                        secondary={secondaryCta}
                        centered={false}
                        isDark={isDark}
                        size="lg"
                        isPrimary={isPrimary}
                        color={color}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6">
                <div className="sm:max-w-full max-h-full sm:mx-auto px-4 py-0 sm:px-10  flex items-center h-full">
                  {chart && (
                    <ChartElement data={data} chart={chart} color={color} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export { BlockHeroChart as default };
