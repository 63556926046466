import React, { Component, Fragment } from "react";
import { FORM_PROVIDER_COMPONENT_MAP } from "./form-provider-component.map";

export class BlockFormNested extends Component {
  getProviderComponent() {
    return FORM_PROVIDER_COMPONENT_MAP[this.props?.data?.provider] || null;
  }

  render() {
    const data = this.props?.data || {};

    if (data && typeof data !== "object") {
      return null;
    }

    const { config, title } = data;
    const ProviderComponent = this.getProviderComponent();
    const provider = this.props?.data?.provider;
    const providerSettings = this.props?.siteConfig?.formProviderSettings;
    const providerBaseConfig = providerSettings?.[provider] || {};

    return (
      <Fragment>
        {ProviderComponent && (
          <div className="flex w-full items-center bg-white z-10 justify-center text-center sm:block">
            {title && (
              <strong className="text-xl leading-6 mb-5 block font-semibold text-gray-900">
                {title}
              </strong>
            )}

            <ProviderComponent
              config={config}
              nested={true}
              conversion={data.conversion}
              modalKey={this.props?.block?.key}
              baseConfig={providerBaseConfig}
              color={this.props?.color}
            />
          </div>
        )}
      </Fragment>
    );
  }
}
