import React from "react";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { IconElement } from "../../elements/icon.element";

export function BlockBannerSticky({ data, color }) {
  const { text, icon, cta = {} } = data;

  const { primary: primaryCta = null, secondary: secondaryCta = null } = cta;

  return (
    <div className={`${color.bg[600]} sticky top-0 z-20`}>
      <div className="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex text-white items-center">
            {icon && (
              <span className={`flex p-2 text-xl rounded-lg ${color.bg[600]}`}>
                <IconElement icon={icon} />
              </span>
            )}
            <p className="ml-3 font-medium text-white truncate">{text}</p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <CtaButtonsElement
              condensed="true"
              size="sm"
              primary={primaryCta}
              secondary={secondaryCta}
              center={false}
              isDark={true}
              color={color}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export { BlockBannerSticky as default };
