import { ExclamationCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

const FORM_MAGIC_ITEMS_MAP = {
    input: FormMagicItemInput,
    text: FormMagicItemText,
    select: FormMagicItemSelect,
};

function FormMagicItemLabel({ id, children, hasErrors = false }) {
    return (
        <label htmlFor={id} className={classNames("block text-sm font-semibold", {
            "text-gray-800": !hasErrors,
            "text-red-500": hasErrors,
        })}>
            {children}
        </label>
    );
}

function FormMagicItemDescription({ children }) {
    return <p className="mt-2 text-sm text-gray-600">{children}</p>;
}

function FormMagicItemErrorMessage({ message }) {
    if (typeof message !== "string") {
        return null;
    }

    return <p className="mt-2 text-sm text-red-600">{message}</p>;
}

function FormMagicItemInput({ item, error, isPrimeError }) {
    const id = uuid();
    const { key, label, attributes, description } = item || {};

    const [postErrorChange, setPostErrorChange] = useState(false);
    const [onErrorValue, setOnErrorValue] = useState("");
    const [inputValue, setInputValue] = useState("");

    const inputRef = useRef(null);

    useEffect(() => {
        if (error) {
            setPostErrorChange(false);
            setOnErrorValue(inputValue);

            if (isPrimeError) {
                inputRef.current.focus();
            }
        }
    }, [error]);

    function handleInput({ target: { value } }) {
        setPostErrorChange(value !== onErrorValue);
        setInputValue(value);
    }

    const showError = error && !postErrorChange;

    return (
        <div className="text-left">
            {label && <FormMagicItemLabel id={id} hasErrors={showError}>{label}</FormMagicItemLabel>}
            <div className={classNames("mt-1", {
                "relative rounded-md shadow-sm": showError,
            })}>
                <input
                    id={id}
                    ref={inputRef}
                    className={classNames(
                        "form-input focus:shadow-none",
                        {
                            "shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md": !showError,
                            "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md": showError,
                        }
                    )}
                    type="text"
                    name={key}
                    onInput={handleInput}
                    {...attributes}
                />

                {showError && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                )}
            </div>

            {showError && (<FormMagicItemErrorMessage message={error?.message} />)}

            {description && !showError && (
                <FormMagicItemDescription>{description}</FormMagicItemDescription>
            )}
        </div>
    );
}

function FormMagicItemText({ item, error, isPrimeError }) {
    const id = uuid();
    const { key, label, attributes, description } = item || {};

    const [postErrorChange, setPostErrorChange] = useState(false);
    const [onErrorValue, setOnErrorValue] = useState("");
    const [inputValue, setInputValue] = useState("");

    const inputRef = useRef(null);

    useEffect(() => {
        if (error) {
            setPostErrorChange(false);
            setOnErrorValue(inputValue);

            if (isPrimeError) {
                inputRef.current.focus();
            }
        }
    }, [error]);

    function handleInput({ target: { value } }) {
        setPostErrorChange(value !== onErrorValue);
        setInputValue(value);
    }

    const showError = error && !postErrorChange;

    return (
        <div className="text-left">
            {label && <FormMagicItemLabel id={id} hasErrors={showError}>{label}</FormMagicItemLabel>}
            <div className={classNames("mt-1", {
                "relative rounded-md shadow-sm": showError,
            })}>
                <textarea
                    id={id}
                    ref={inputRef}
                    name={key}
                    {...attributes}
                    onInput={handleInput}
                    className={classNames(
                        "form-input focus:shadow-none block w-full sm:text-sm  rounded-md",
                        {
                            "max-w-lg shadow-sm focus:ring-pink-500 focus:border-pink-500 border border-gray-300": !showError,
                            "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500": showError,
                        }
                    )}
                ></textarea>

                {showError && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                )}
            </div>

            {showError && (<FormMagicItemErrorMessage message={error?.message} />)}

            {description && !showError && (
                <FormMagicItemDescription>{description}</FormMagicItemDescription>
            )}
        </div>
    );
}

function FormMagicItemSelect({ item, error, isPrimeError }) {
    const id = uuid();
    const { key, label, attributes, options, description } = item || {};

    const [postErrorChange, setPostErrorChange] = useState(false);
    const [onErrorValue, setOnErrorValue] = useState("");
    const [inputValue, setInputValue] = useState("");

    const inputRef = useRef(null);

    useEffect(() => {
        if (error) {
            setPostErrorChange(false);
            setOnErrorValue(inputValue);

            if (isPrimeError) {
                inputRef.current.focus();
            }
        }
    }, [error]);

    function handleInput({ target: { value } }) {
        setPostErrorChange(value !== onErrorValue);
        setInputValue(value);
    }

    const showError = error && !postErrorChange;

    if (!Array.isArray(options)) {
        return null;
    }

    const defaultValue = options.find(({ selected }) => selected) || options[0];

    return (
        <div className="text-left">
            {label && <FormMagicItemLabel id={id} hasErrors={showError}>{label}</FormMagicItemLabel>}
            <div className="mt-1">
                <select
                    id={id}
                    ref={inputRef}
                    className={classNames("form-select focus:shadow-none mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md", {
                        "focus:ring-red-500 border-red-500": showError,
                        "focus:ring-pink-500 focus:border-pink-500": !showError,
                    })}
                    name={key}
                    defaultValue={defaultValue?.value}
                    onInput={handleInput}
                    {...attributes}
                >
                    {Array.isArray(options) &&
                        options.map(({ label, value }) => {
                            return (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            );
                        })}
                </select>
            </div>

            {showError && (<FormMagicItemErrorMessage message={error?.message} />)}

            {description && !showError && (
                <FormMagicItemDescription>{description}</FormMagicItemDescription>
            )}

        </div>
    );
}

export { FORM_MAGIC_ITEMS_MAP };
