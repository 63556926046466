import React from "react";
import { SectionTitleElement } from "../../elements/section-title.element";
import { MarkdownElement } from "../../elements/markdown.element";

export function BlockFaqCols({ data, color }) {
  const { title, questions, isPrimary, isDark } = data || {};

  return (
    <div>
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        {title && (
          <SectionTitleElement
            text={title}
            isPrimary={isPrimary}
            isDark={isDark}
            isCenter={true}
            color={color}
            size="3xl"
          />
        )}

        <div className="mt-12">
          <ul className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
            {Array.isArray(questions) &&
              questions.map(({ question, answer }) => {
                return (
                  <li className="space-y-2" key={question}>
                    <div className="text-lg mb-4 leading-6 font-medium text-gray-900">
                      {question}
                    </div>
                    <div className="text-base leading-6 text-gray-700">
                      <MarkdownElement
                        isDark={isDark}
                        isPrimary={isPrimary}
                        size="md"
                        color={color}
                        proseMode={false}
                      >
                        {answer}
                      </MarkdownElement>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export { BlockFaqCols as default };
