import React from "react";
import { FeatureSubtitleElement } from "./elements/feature.subtitle.element";
import { FeatureTitleElement } from "./elements/feature.title.element";
import { FeatureDescriptionElement } from "./elements/feature.description.element";
import { classNames } from "../../../helpers/class-names.helper";
import { IconElement } from "../../elements/icon.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { MarkdownElement } from "../../elements/markdown.element";

export function BlockFeatureGrid({ data = {}, color }) {
  const {
    title,
    subtitle,
    cta = {},
    description,
    features,
    cols = 4,
    isDark = false,
    isPrimary = false,
  } = data;
  const { primary: primaryCta, secondary: secondaryCta } = cta;

  const colsClassNames = {
    4: "md:grid md:grid-cols-4 md:gap-x-8 md:gap-y-10",
    3: "md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10",
    2: "md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10",
    1: "md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10",
  };

  const colsClassName =
    colsClassNames?.[cols] || "md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10";

  return (
    <div
      className={classNames([
        !!(subtitle || title || description) && "py-12 lg:py-16",
        !(subtitle || title || description) && "py-8",
      ])}
    >
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto lg:text-center">
          <FeatureSubtitleElement
            title={subtitle}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />
          <FeatureTitleElement
            title={title}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />
          <FeatureDescriptionElement
            text={description}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />
        </div>

        <div
          className={classNames([
            (subtitle || title || description) && "mt-10",
          ])}
        >
          {Array.isArray(features) && (
            <ul className={colsClassName}>
              {features.map(({ title, text, description, icon }) => {
                return (
                  <li key={title} className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div
                          className={classNames([
                            "flex items-center justify-center h-12 w-12 rounded-md",
                            isPrimary && `${color.bg[600]} text-white`,
                            isDark && "bg-gray-600 text-white",
                            !isPrimary &&
                              !isDark &&
                              `${color.bg[500]} text-white`,
                          ])}
                        >
                          {icon && (
                            <span className="inline text-center h-6 w-6 text-lg">
                              <IconElement icon={icon} />
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="ml-4">
                        <strong
                          className={classNames([
                            "block text-lg leading-6 font-normal font-sans",
                            isPrimary && "text-white",
                            isDark && "text-gray-100",
                            !isPrimary && !isDark && "text-gray-900",
                          ])}
                        >
                          {title}
                        </strong>
                        <div
                          className={classNames([
                            "mt-2 text-base leading-6",
                            isPrimary && color.text[50],
                            isDark && "text-gray-400",
                            !isPrimary && !isDark && "text-gray-700",
                          ])}
                        >
                          <MarkdownElement
                            isPrimary={isPrimary}
                            isDark={isDark}
                            color={color}
                            proseMode={false}
                            size="md"
                          >
                            {description}
                          </MarkdownElement>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <div className="mt-10 md:mt-16">
          <CtaButtonsElement
            primary={primaryCta}
            secondary={secondaryCta}
            center={true}
            isDark={isDark || isPrimary}
            color={color}
          />
        </div>
      </div>
    </div>
  );
}

export { BlockFeatureGrid as default };
