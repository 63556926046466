import { scaleLinear } from "d3-scale";
import { interpolateRdYlGn } from "d3-scale-chromatic";
import React, { Fragment, useState } from "react";

const localColorInterpolator = interpolateRdYlGn;

function sumArray(values) {
  if (!Array.isArray(values)) {
    return null;
  }

  return values.reduce((a, b) => {
    return a + b;
  }, 0);
}

function mergeValueArrays(a, b) {
  const c = [];
  for (let i = 0; i < Math.max(a.length, b.length); i++) {
    c.push((a[i] || 0) + (b[i] || 0));
  }
  return c;
}

function calculateKpiScore(values, startsWith = 1) {
  const merged = values.reduce((a, c, idx) => {
    if (idx === 0) {
      return c;
    }

    return mergeValueArrays(a, c);
  }, []);

  return calculateScore(merged, startsWith);
}

function calculateScore(values, startsWith = 1) {
  if (!Array.isArray(values)) {
    return null;
  }

  const relativeInterpolator = scaleLinear([1, 5], [0, 1]);
  const count = sumArray(values);
  const valueScores = values.map((value, idx) => {
    return value * (idx + startsWith);
  });

  const sum = sumArray(valueScores);

  const frequencyDistribution = values.map((value, idx) => {
    const scaleValue = idx + startsWith;

    return {
      count: value,
      scaleValue,
      relative: count ? value / count : 0,
      color: localColorInterpolator(relativeInterpolator(scaleValue)),
    };
  });

  const average = count > 0 ? sum / count : null;
  const relative = relativeInterpolator(average);
  const color = localColorInterpolator(relative);

  return {
    count,
    frequencyDistribution,
    valueScores,
    sum,
    relative,
    color,
    average,
  };
}

function ReportNavbarElement({
  data = {},
  color,
  currentNavigationStep: currentStep,
  setCurrentNavigationStep,
}) {
  const { navigation } = data;

  if (!navigation) {
    return null;
  }

  const currentIndex = navigation.findIndex(item => item.step === currentStep);

  return (
    <nav aria-label="Progress">
      <ol className="overflow-hidden">
        {Array.isArray(navigation) &&
          navigation.map((item, idx) => {
            const { step, title, description } = item;

            const isLast = idx + 1 === navigation.length;
            const isCurrent = step === currentStep;
            const isBeforeCurrent = idx < currentIndex;

            return (
              <li key={step} className={`relative ${!isLast ? "pb-10" : ""}`}>
                <div
                  className={`-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300 transition-color duration-100 ${
                    isLast ? "hidden" : ""
                  } ${isBeforeCurrent ? color.bg[600] : ""}`}
                  aria-hidden="true"
                ></div>
                <div
                  role="button"
                  tabIndex={0}
                  className="cursor-pointer relative flex items-start group"
                  onClick={() => setCurrentNavigationStep(step)}
                  onKeyPress={() => setCurrentNavigationStep()}
                >
                  <span className="h-9 flex items-center" aria-hidden="true">
                    <span
                      className={`relative z-10 w-8 h-8 flex items-center justify-center
                      transition-colors duration-100 
                      ${
                        isCurrent
                          ? color.border[600]
                          : " border-gray-300 group-hover:border-gray-400 "
                      }  
                      
                      ${
                        isBeforeCurrent
                          ? color.bg[600] + " group-hover:" + color.bg[500]
                          : " bg-white border-2"
                      }
                      
                        rounded-full`}
                    >
                      {isBeforeCurrent && (
                        <svg
                          className="w-5 h-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}

                      {!isBeforeCurrent && (
                        <span
                          className={`h-2.5 w-2.5 transform transition-all duration-100 rounded-full
                        ${
                          isCurrent
                            ? color.bg[600]
                            : "bg-transparent group-hover:bg-gray-300"
                        }`}
                        ></span>
                      )}
                    </span>
                  </span>
                  <span className="ml-4 min-w-0 flex flex-col">
                    {title && (
                      <span
                        className={`text-sm transition-colors duration-100 font-semibold uppercase tracking-wide ${
                          isCurrent
                            ? color.text[600]
                            : "text-gray-600 group-hover:text-gray-700"
                        }`}
                      >
                        {title}
                      </span>
                    )}

                    {description && (
                      <span
                        className={`text-sm pt-1 ${
                          isCurrent ? "text-gray-700" : "text-gray-600"
                        }`}
                      >
                        {description}
                      </span>
                    )}
                  </span>
                </div>
              </li>
            );
          })}
      </ol>
    </nav>
  );
}

export function BlockReportTwentyNineFive({ data = {}, color }) {
  const { company, team, period, comparePeriod, items, navigation } = data;

  const [currentNavigationStep, setCurrentNavigationStep] = useState(
    navigation[0].step
  );

  const overallScore = calculateKpiScore(
    [...items.map(item => item.questions).flat()].map(question => {
      return question.values;
    })
  );

  // const overallScorePrevious = calculateKpiScore(
  //   [...items.map(item => item.questions).flat()].map(question => {
  //     return question.valuesPrevious;
  //   })
  // );

  // const overallChangeAbsolute =
  //   overallScore.average - overallScorePrevious.average;
  // const overallChangeRelative =
  //   overallChangeAbsolute / overallScorePrevious.average;

  return (
    <div className="hidden md:block">
      <div className="w-screen-xl max-w-full  overflow-hidden flex flex-col-reverse md:flex-row justify-center items-center mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="px-4 rounded-l-md py-6 bg-gray-100 z-0 w-72 self-start mt-10">
          {ReportNavbarElement({
            data,
            color,
            currentNavigationStep,
            setCurrentNavigationStep,
          })}
        </div>

        <div className="bg-white rounded-lg shadow-xl px-4 py-5 sm:p-6 sm:pb-0 sm:pt-4 z-10">
          <div className="md:flex md:items-center md:justify-between md:space-x-5">
            <div className="flex items-start space-x-5">
              {/* <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                    alt=""
                  />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  ></span>
                </div>
              </div> */}
              <div className="pt-0">
                <strong className="text-2xl font-bold text-gray-900">
                  {team}
                </strong>
                <p className="text-sm font-normal text-gray-500">
                  <span className="text-gray-900 font-medium">{company}</span>
                  &nbsp;/&nbsp;
                  <time className="text-gray-900 font-medium">{period}</time>
                  &nbsp;im Vergleich mit&nbsp;
                  <time className="font-medium">&nbsp;{comparePeriod}</time>
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <div className="mt-1 flex justify-between items-baseline md:table-cell lg:flex">
                <div
                  className="flex items-baseline text-3xl font-semibold px-3 py-1.5 rounded"
                  style={{ backgroundColor: overallScore.color }}
                >
                  <div className="flex justify-center content-center items-center">
                    {overallScore && overallScore.average.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col my-4 -mx-6 mb-0">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="rounded-b-md overflow-hidden border-b border-gray-200">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Kennzahl
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Score
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Veränderung
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell"
                        >
                          Verteilung
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Antworten
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {Array.isArray(items) &&
                        items.map(item => {
                          const {
                            title: kpiTitle,
                            explainer,
                            questions,
                            key,
                          } = item;

                          const questionValues = questions.map(
                            question => question.values
                          );

                          const score = calculateKpiScore(questionValues);

                          const scorePrevious = calculateKpiScore(
                            questions.map(question => question.valuesPrevious)
                          );

                          const changeAbsolute =
                            score.average - scorePrevious.average;
                          const changeRelative =
                            changeAbsolute / scorePrevious.average;

                          const isDrilldown =
                            key === "leadership" &&
                            currentNavigationStep === "teamKpiDrilldown";

                          return (
                            <Fragment key={key}>
                              <tr
                                className={`transition-color duration-200 ${
                                  isDrilldown ? "bg-gray-200" : ""
                                }`}
                              >
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="flex items-center">
                                    <div>
                                      <div
                                        className={`text-base font-medium ${
                                          isDrilldown
                                            ? " text-gray-900"
                                            : " text-gray-900"
                                        }`}
                                      >
                                        {kpiTitle}
                                      </div>
                                      {explainer && false && (
                                        <div className="text-sm text-gray-600  mt-2">
                                          {explainer}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="flex justify-center content-center items-center">
                                    <div
                                      className="w-3 h-3 rounded-full"
                                      style={{
                                        backgroundColor: score.color,
                                      }}
                                    ></div>

                                    <div className="text-xl font-semibold text-gray-900 px-3 py-1.5 rounded w-auto border-0">
                                      {score.average.toFixed(2)}
                                    </div>
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="flex justify-center content-center items-center">
                                    {changeRelative < 0 && (
                                      <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100x text-red-800 md:mt-2 lg:mt-0">
                                        <svg
                                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                                          fill="currentColor"
                                          viewBox="0 0 20 20"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                        <span className="sr-only">
                                          Decreased by
                                        </span>
                                        {(
                                          Math.abs(changeRelative) * 100
                                        ).toFixed(1)}
                                        %
                                      </div>
                                    )}

                                    {changeRelative > 0 && (
                                      <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100x text-green-800 md:mt-2 lg:mt-0">
                                        <svg
                                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                                          fill="currentColor"
                                          viewBox="0 0 20 20"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                        <span className="sr-only">
                                          Increased by
                                        </span>
                                        {(
                                          Math.abs(changeRelative) * 100
                                        ).toFixed(1)}
                                        %
                                      </div>
                                    )}

                                    {changeRelative === 0 && (
                                      <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-100x text-gray-800 md:mt-2 lg:mt-0">
                                        <span className="sr-only">
                                          Unchanged
                                        </span>
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap hidden md:table-cell">
                                  <ul className="flex justify-start w-full h-2 bg-gray-100 rounded-sm overflow-hidden">
                                    {score &&
                                      Array.isArray(
                                        score.frequencyDistribution
                                      ) &&
                                      score.frequencyDistribution.map(
                                        frequency => {
                                          return (
                                            <li
                                              key={frequency.scaleValue}
                                              className="h-full transition duration-100"
                                              style={{
                                                width: `${
                                                  frequency.relative * 100
                                                }%`,
                                                backgroundColor:
                                                  frequency.color,
                                              }}
                                            ></li>
                                          );
                                        }
                                      )}
                                  </ul>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="flex justify-end text-gray-900 text-sm content-center items-center">
                                    {Intl.NumberFormat().format(score.count)}
                                  </div>
                                </td>
                              </tr>

                              {questions &&
                                isDrilldown &&
                                questions.map(q => {
                                  const {
                                    title,
                                    question,
                                    values,
                                    valuesPrevious,
                                  } = q;

                                  const questionScore = calculateScore(values);

                                  const questionScorePrevious = calculateScore(
                                    valuesPrevious
                                  );

                                  const questionChangeAbsolute =
                                    questionScore.average -
                                    questionScorePrevious.average;
                                  const questionChangeRelative =
                                    questionChangeAbsolute /
                                    questionScorePrevious.average;

                                  return (
                                    <tr
                                      key={title}
                                      className="divide-opacity-50 bg-gray-50"
                                    >
                                      <td className="pl-8 px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                          <div>
                                            <div className="text-sm font-regular text-gray-700">
                                              {title}
                                            </div>
                                            {question && false && (
                                              <div className="text-xs text-gray-600 mt-2">
                                                {question}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap border-red-500">
                                        <div className="flex justify-center content-center items-center">
                                          <div
                                            className="w-2 h-2 rounded-full"
                                            style={{
                                              backgroundColor:
                                                questionScore.color,
                                            }}
                                          ></div>

                                          <div className="text-base font-medium text-gray-700 px-3 py-1.5 rounded w-auto border-0">
                                            {questionScore.average.toFixed(2)}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex justify-center content-center items-center">
                                          {questionChangeRelative < 0 && (
                                            <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100x text-red-800 md:mt-2 lg:mt-0">
                                              <svg
                                                className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                aria-hidden="true"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                              <span className="sr-only">
                                                Decreased by
                                              </span>
                                              {(
                                                Math.abs(
                                                  questionChangeRelative
                                                ) * 100
                                              ).toFixed(1)}
                                              %
                                            </div>
                                          )}

                                          {questionChangeRelative > 0 && (
                                            <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100x text-green-800 md:mt-2 lg:mt-0">
                                              <svg
                                                className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                aria-hidden="true"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                              <span className="sr-only">
                                                Increased by
                                              </span>
                                              {(
                                                Math.abs(
                                                  questionChangeRelative
                                                ) * 100
                                              ).toFixed(1)}
                                              %
                                            </div>
                                          )}

                                          {questionChangeRelative === 0 && (
                                            <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-100x text-gray-800 md:mt-2 lg:mt-0">
                                              <span className="sr-only">
                                                Unchanged
                                              </span>
                                              -
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap hidden md:table-cell">
                                        <ul className="flex justify-start w-full h-2 bg-gray-100 rounded-sm overflow-hidden">
                                          {questionScore &&
                                            Array.isArray(
                                              questionScore.frequencyDistribution
                                            ) &&
                                            questionScore.frequencyDistribution.map(
                                              frequency => {
                                                return (
                                                  <li
                                                    key={frequency.scaleValue}
                                                    className="h-full transition duration-100"
                                                    style={{
                                                      width: `${
                                                        frequency.relative * 100
                                                      }%`,
                                                      backgroundColor:
                                                        frequency.color,
                                                    }}
                                                  ></li>
                                                );
                                              }
                                            )}
                                        </ul>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex justify-end text-gray-900 text-sm content-center items-center">
                                          {Intl.NumberFormat().format(
                                            questionScore.count
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </Fragment>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
