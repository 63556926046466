import React, { Fragment } from "react";
import { HeroTitleElement } from "./elements/hero.title.element";
import { HeroLeadElement } from "./elements/hero.lead.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { ImageAssetElement } from "../../elements/image-asset.element";
import { NavbarTopCenteredElement } from "../../elements/navbar/navbar.top.centered.element";

export function BlockHeroVideo({ data, assets, color }) {
  const {
    title,
    lead,
    image,
    navigation = {},
    isDark = false,
    isPrimary = false,
    cta = {},
  } = data;

  const { primary: primaryCta = null, secondary: secondaryCta = null } = cta;

  return (
    <Fragment>
      <div className="relative overflow-hidden">
        <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
          <NavbarTopCenteredElement
            data={navigation}
            isDark={isDark}
            isPrimary={isPrimary}
            assets={assets}
            color={color}
          />

          <main className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                <HeroTitleElement
                  title={title}
                  isDark={isDark}
                  isPrimary={isPrimary}
                  color={color}
                />
                <HeroLeadElement
                  text={lead}
                  isDark={isDark}
                  isPrimary={isPrimary}
                  color={color}
                />

                {cta && (
                  <div className="flex items-center justify-start sm:justify-center lg:justify-start">
                    <CtaButtonsElement
                      primary={primaryCta}
                      secondary={secondaryCta}
                      centered={false}
                      isDark={isDark}
                      size="lg"
                      isPrimary={isPrimary}
                      color={color}
                    />
                  </div>
                )}
              </div>
              <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <svg
                  className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                  width="640"
                  height="784"
                  fill="none"
                  viewBox="0 0 640 784"
                >
                  <defs>
                    <pattern
                      id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                      x="118"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    y="72"
                    width="640"
                    height="640"
                    className="text-gray-50"
                    fill="currentColor"
                  />
                  <rect
                    x="118"
                    width="404"
                    height="784"
                    fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
                  />
                </svg>
                <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                  <button
                    type="button"
                    className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline"
                  >
                    {image && (
                      <ImageAssetElement
                        isFluid={true}
                        assetKey={image}
                        assets={assets}
                      />
                    )}
                    <div className="absolute inset-0 w-full h-full flex items-center justify-center">
                      <svg
                        className={`h-20 w-20 ${color.text[500]}`}
                        fill="currentColor"
                        viewBox="0 0 84 84"
                      >
                        <circle
                          opacity="0.9"
                          cx="42"
                          cy="42"
                          r="42"
                          fill="white"
                        />
                        <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
}

export { BlockHeroVideo as default };
