import React from "react";

export function BlockHeaderBold({ data, color }) {
  const { title, subtitle, lead } = data;

  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          {subtitle && (
            <span
              className={`text-base leading-6 font-semibold ${color.text[600]} tracking-wide uppercase`}
            >
              {subtitle}
            </span>
          )}
          {title && (
            <h1 className="mt-1 text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-none sm:tracking-tight lg:text-5xl">
              {title}
            </h1>
          )}
          {lead && (
            <p className="max-w-xl mt-5 mx-auto text-lg sm:text-xl leading-7 text-gray-700">
              {lead}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export { BlockHeaderBold as default };
