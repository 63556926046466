import React from "react";
import { HeroTitleElement } from "./elements/hero.title.element";
import { HeroLeadElement } from "./elements/hero.lead.element";
import { classNames } from "../../../helpers/class-names.helper";
import { NavbarTopCenteredElement } from "../../elements/navbar/navbar.top.centered.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { TitleLabelElement } from "../../elements/title-label.element";
import { IconElement } from "../../elements/icon.element";

function NavigatorItemElement({ item, color }) {
  const { key, title, link, icon, description } = item;

  return (
    <div key={key} className={`relative group bg-white p-6`}>
      <div>
        <span
          className={`rounded inline-flex p-3 px-3.5 text-gray-800  bg-gray-800 group-hover:${color.bg[500]} transition-colors duration-200 ${color.text[50]} ring-4 ring-white  text-2xl`}
        >
          <IconElement icon={icon} />
        </span>
      </div>
      <div className="mt-8">
        <h3 className="text-lg font-medium">
          <a
            href={link}
            className={`group-hover:${color.text[500]} focus:outline-none transition-colors duration-200`}
          >
            <span className="absolute inset-0" aria-hidden="true"></span>
            {title}
          </a>
        </h3>
        {description && (
          <p className="mt-2 text-sm text-gray-700">{description}</p>
        )}
      </div>
      <span
        className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
        aria-hidden="true"
      ></span>
    </div>
  );
}

function NavigatorElement({ navigator, color }) {
  if (!Array.isArray(navigator)) {
    return null;
  }

  return (
    <div className="shadow divide-y sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-1">
      {Array.isArray(navigator) &&
        navigator.map(item => {
          return (
            <NavigatorItemElement item={item} color={color} key={item.key} />
          );
        })}
    </div>
  );
}

export function BlockHeroNavigator({ data, assets, color }) {
  const {
    subtitle = null,
    title,
    lead,
    navigation = {},
    isDark = false,
    isPrimary = false,
    cta = {},
    navigator,
  } = data;

  const { primary: primaryCta = null, secondary: secondaryCta = null } = cta;

  return (
    <div className={classNames(["relative", "overflow-hidden"])}>
      <NavbarTopCenteredElement
        data={navigation}
        isDark={isDark}
        isPrimary={isPrimary}
        assets={assets}
        color={color}
      />

      <div className="relative pt-6 pb-12 sm:pb-32">
        <main className="mt-8 sm:mt-16 md:mt-20 lg:mt-24">
          <div className="mx-auto max-w-screen-xl">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                <div>
                  {subtitle && (
                    <TitleLabelElement
                      text={subtitle}
                      isDark={isDark}
                      isPrimary={isPrimary}
                      color={color}
                    />
                  )}
                  <HeroTitleElement
                    title={title}
                    isDark={isDark}
                    isPrimary={isPrimary}
                    color={color}
                  />
                  <HeroLeadElement
                    text={lead}
                    isDark={isDark}
                    isPrimary={isPrimary}
                    color={color}
                  />

                  {cta && (
                    <div className="flex items-center justify-start sm:justify-center lg:justify-start">
                      <CtaButtonsElement
                        primary={primaryCta}
                        secondary={secondaryCta}
                        centered={false}
                        isDark={isDark}
                        size="lg"
                        isPrimary={isPrimary}
                        color={color}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6">
                <div className="sm:max-w-full max-h-full sm:mx-auto px-4 py-0 sm:px-10  flex items-center h-full">
                  {Array.isArray(navigator) && (
                    <NavigatorElement navigator={navigator} color={color} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export { BlockHeroNavigator as default };
