import React from "react";
import { FeatureTitleElement } from "./elements/feature.title.element";
import { FeatureSubtitleElement } from "./elements/feature.subtitle.element";
import { FeatureDescriptionElement } from "./elements/feature.description.element";
import { IconElement } from "../../elements/icon.element";
import { classNames } from "../../../helpers/class-names.helper";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";

export function BlockFeatureList({ data = {}, color }) {
  const {
    title,
    subtitle,
    cta = {},
    description,
    features,
    cols = 4,
    isDark = false,
    isPrimary = false,
  } = data;
  const { primary: primaryCta, secondary: secondaryCta } = cta;

  const colsClassNames = {
    4: "sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8",
    3: "sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 lg:gap-x-10",
    2: "sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-2 lg:gap-x-12",
    1: "sm:grid-cols-1 sm:gap-x-6 lg:grid-cols-1 lg:gap-x-16",
  };

  const colsClassName =
    colsClassNames?.[cols] ||
    "sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8";

  return (
    <div>
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="max-w-3xl mx-auto lg:text-center">
          <FeatureSubtitleElement
            title={subtitle}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />
          <FeatureTitleElement
            title={title}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />
          <FeatureDescriptionElement
            text={description}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />
        </div>

        {Array.isArray(features) && (
          <dl
            className={classNames([
              "mt-12 space-y-10 sm:space-y-0 sm:grid sm:gap-y-12",
              colsClassName,
            ])}
          >
            {features.map(({ title, description, icon }, idx) => {
              return (
                <div className="flex space-x-8 mr-10 last:mr-0" key={idx}>
                  {icon && (
                    <span
                      className={classNames([
                        "flex-shrink-0 h-6 w-6 text-lg",
                        isPrimary && color.text[200],
                        isDark && "text-gray-200",
                        !isPrimary && !isDark && color.text[500],
                      ])}
                    >
                      <IconElement icon={icon} />
                    </span>
                  )}

                  <div className="space-y-2">
                    {title && (
                      <dt
                        className={classNames([
                          "text-lg leading-6  font-medium font-sans",
                          isPrimary && "text-white",
                          isDark && "text-gray-100",
                          !isPrimary && !isDark && "text-gray-900",
                        ])}
                      >
                        {title}
                      </dt>
                    )}

                    <dd className="flex space-x-3 lg:py-0 lg:pb-4">
                      {description && (
                        <span
                          className={classNames([
                            "text-base leading-6",
                            isPrimary && color.text[200],
                            isDark && "text-gray-400",
                            !isPrimary && !isDark && "text-gray-700",
                          ])}
                        >
                          {description}
                        </span>
                      )}
                    </dd>
                  </div>
                </div>
              );
            })}
          </dl>
        )}

        <CtaButtonsElement
          primary={primaryCta}
          secondary={secondaryCta}
          center={true}
          isDark={isDark || isPrimary}
          color={color}
        />
      </div>
    </div>
  );
}

export { BlockFeatureList as default };
