import React, { Fragment } from "react";
import { classNames } from "../../../../helpers/class-names.helper";

export function FeatureTitleElement({
  title,
  isDark = false,
  isPrimary = false,
  color,
}) {
  return (
    <Fragment>
      {title && (
        <h2
          className={classNames([
            "text-3xl leading-9 font-extrabold sm:text-4xl sm:leading-12",
            isDark && "text-gray-100",
            isPrimary && "text-white",
            !isDark && !isPrimary && "text-gray-900",
          ])}
        >
          {title && typeof title === "string" && (
            <span className="block">{title}</span>
          )}
          {title &&
            Array.isArray(title) &&
            title.map((titleChunk, idx) => {
              const classNames =
                idx % 2 && !(isPrimary || isDark)
                  ? `block ${color.text[600]}`
                  : isDark && idx % 2
                  ? `block ${color.text[400]}`
                  : "block";
              return (
                <span className={classNames} key={idx}>
                  {titleChunk}
                </span>
              );
            })}
        </h2>
      )}
    </Fragment>
  );
}
