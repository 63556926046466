import React, { Component, Fragment } from "react";
import { FORM_PROVIDER_COMPONENT_MAP } from "./form-provider-component.map";

export class BlockFormEmbedded extends Component {
  getProviderComponent() {
    return FORM_PROVIDER_COMPONENT_MAP[this.props?.data?.provider] || null;
  }

  render() {
    const data = this.props?.data || {};

    if (data && typeof data !== "object") {
      return null;
    }

    const { config, title } = data;
    const ProviderComponent = this.getProviderComponent();
    const providerBaseConfig =
      this.props?.siteConfig?.formProviderSettings?.[
        this.props?.data?.provider
      ] || {};

    return (
      <Fragment>
        <h2>{title}</h2>
        {ProviderComponent && (
          <ProviderComponent config={config} baseConfig={providerBaseConfig} />
        )}
      </Fragment>
    );
  }
}
