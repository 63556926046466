import React from "react";

export function BlockHeaderLeftDark({ data }) {
  const { title, subtitle, lead } = data;

  return (
    <div className="bg-gray-800">
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between">
        <div className="max-w-xl">
          {subtitle && (
            <span className="text-base leading-6 mb-2 block font-semibold text-gray-200 tracking-wide uppercase">
              {subtitle}
            </span>
          )}

          {title && (
            <h1 className="text-4xl leading-10 font-extrabold text-white sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
              {title}
            </h1>
          )}

          {lead && (
            <p className="mt-5 text-xl leading-7 text-gray-400">{lead}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export { BlockHeaderLeftDark as default };
