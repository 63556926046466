import React, { Component } from "react";
import classNames from "classnames";
import { createBaseEvent } from "../../../../events/event-creator";
import { conversionTrigger, fireEvent } from "../../../../events/events.helper";

export class FormSharpspringElement extends Component {
  #sharpspringFormListener;

  constructor(props) {
    super(props);

    this.watchDog = this.watchDog.bind(this);

    this.randomTitle = null;

    this.iframeUrl = null;

    this.state = {
      height: null,
      width: null,
      iframeUrl: null,
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      iframeUrl: this.getIframeUrl(),
    });
    this.randomTitle = Math.random().toString();
    window.addEventListener("message", this.watchDog, false);
  }

  watchDog(event) {
    if (event.origin.toLowerCase() !== this.getOrigin()) {
      return;
    }

    if (
      (event.data?.formID || event.data?.formId) !== this.props?.config?.formId
    ) {
      return;
    }

    return this.handleSharpspringEvents(event);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.watchDog);
  }

  handleSharpspringEvents({ data }) {
    const { action } = data;

    switch (action) {
      case "resize":
        return this.resizeFrame(data);
      case "redirect":
        return this.redirectPage(data);
      case "conversion":
        return this.conversionSuccess(data);
      default:
        return;
    }
  }

  resizeFrame({ width, height }) {
    this.setState({
      width,
      height,
    });
  }

  redirectPage({ url }) {
    window.location.href = url;
  }

  conversionSuccess() {
    if (this.props?.nested && this.props?.conversion) {
      conversionTrigger(this.props.conversion);
    }

    if (!this.props?.nested) {
      fireEvent(
        createBaseEvent("modal", "conversion", {
          modalKey: this.props?.modalKey,
        })
      );
    }
  }

  getOrigin() {
    const { domain } =
      { ...this.props?.baseConfig, ...this.props?.config } || {};

    return `https://${domain}`.toLowerCase();
  }

  getUtmParamsMap() {
    if (window && window.__utm === "undefined") {
      return [];
    }

    const utmMap = window.__utm.utmMap();

    const {
      baseConfig: { utmFieldMappings },
    } = this.props;

    return Object.entries(utmFieldMappings)
      .map(([utmKey, fieldName]) => {
        const utmValue = utmMap[utmKey];
        if (!utmValue) {
          return null;
        }

        return [fieldName, utmValue];
      })
      .filter(item => item !== null);
  }

  getTrackerParams() {
    const utmParamsMap = this.getUtmParamsMap();

    return [
      ["rf__sb", encodeURIComponent(window.location.href)],
      ["__ss_tk", null],
      ["__tk", null],
      ...utmParamsMap,
    ]
      .map(([key, value]) => {
        if (!(value && key)) {
          return null;
        }

        return [key, value].join("=");
      })
      .filter(chunk => !!chunk)
      .join("&");
  }

  getIframeUrl() {
    const { baseConfig } = this.props;
    const { account, formId } = { ...baseConfig, ...this.props?.config } || {};
    const origin = this.getOrigin();
    const trackerParams = this.getTrackerParams();

    return `${origin}/prospector/form/${account}/${formId}?${trackerParams}`;
  }

  render() {
    const { height } = this.state;

    return (
      <div
        className={this.props.className + " w-full relative overflow-hidden"}
      >
        <div
          className={classNames({
            "flex justify-center items-center h-32": true,
            "transition-all duration-500 ease-out": true,
            "opacity-0": height > 0,
            "opacity-100": height === null,
          })}
          style={{
            height: height,
            willChange: "height, opacity",
          }}
        >
          <svg
            className="animate-spin h-12 w-12 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>

        <iframe
          src={this.state.iframeUrl}
          title={this.randomTitle}
          className={classNames({
            "absolute inset-0 overflow-hidden w-full transition-opacity duration-100 delay-300": true,
            "opacity-100": height > 0,
            "opacity-0": height === null,
          })}
          scrolling="no"
          allowtransparency="true"
          style={{
            height: height || 1,
            opacity: height > 0 ? 1 : 0,
          }}
        ></iframe>
      </div>
    );
  }
}
