import React from "react";
import { motion } from "framer-motion";

const variants = {
  active: {
    // y: 0,
    lineHeight: "1.4em",
    opacity: 1,
  },
  hold: {
    // y: "0px",
    lineHeight: "1.6em",
    opacity: 0,
  },
};

export function SceneDescriptionElement({ description, isActiveScene }) {
  if (typeof description !== "string") {
    return null;
  }

  return (
    <motion.div
      animate={isActiveScene ? "active" : "hold"}
      variants={variants}
      transition={{ duration: 0.8, delay: isActiveScene && 1 }}
      className="mt-3 max-w-sm text-base text-gray-700 whitespace-pre-line"
    >
      {description}
    </motion.div>
  );
}
