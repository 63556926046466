import React, { Fragment } from "react";
import { BLOCKS_MAP } from "./blocks.map";
import { classNames } from "../../helpers/class-names.helper";

function getComponent(type, variant = null) {
  const typeComponent = BLOCKS_MAP[type] || null;

  if (!typeComponent) {
    return () => (
      <div>
        <pre>Block type: {type} not found.</pre>
      </div>
    );
  }

  if (!variant) {
    if (!typeComponent.default) {
      return () => (
        <span>
          <pre>Block type: {type} found. But no default component defined.</pre>
        </span>
      );
    }

    return typeComponent.default;
  }

  const variantComponent = typeComponent[variant] || null;

  if (!variantComponent) {
    return () => (
      <p>
        <pre>
          Block type: {type} has no variant {variant}.
        </pre>
      </p>
    );
  }

  return variantComponent;
}

export function BlockLoader({ block, page, color, siteConfig }) {
  if (!block) {
    return null;
  }

  const { type, variant, assets, data = {}, fragment } = block;

  const keyedAssets =
    Array.isArray(assets) && assets.length
      ? assets.reduce((a, c) => {
          a[c.key] = c;
          return a;
        }, {})
      : null;

  const BlockComponent = getComponent(type, variant);

  const { isDark = false, isPrimary = false } = data;

  return (
    <Fragment>
      {fragment && (
        <BlockComponent
          data={block.data}
          assets={keyedAssets}
          block={block}
          color={color}
          siteConfig={siteConfig}
        />
      )}

      {!fragment && (
        <section
          id={block.key}
          className={classNames([
            "mx-auto max-w-screen-2xl w-full lg:rounded",
            !isDark && !isPrimary && "bg-white",
            isPrimary && color.bg[600],
            isDark && "bg-gray-800",
          ])}
        >
          <BlockComponent
            page={page}
            data={block.data}
            assets={keyedAssets}
            block={block}
            color={color}
            siteConfig={siteConfig}
          />
        </section>
      )}
    </Fragment>
  );
}
