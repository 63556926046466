import React from "react";
import Img from "gatsby-image";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { DescriptionElement } from "../../elements/description.element";
import classNames from "classnames";
import { SectionTitleElement } from "../../elements/section-title.element";

export function BlockCtaImage({ data, assets, color }) {
  const { title, description, cta, image, isDark, isPrimary } = data;
  const { primary: primaryCta, secondary: secondaryCta } = cta;

  const imageAsset = image && assets && assets[image] ? assets[image] : null;

  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div
          className={classNames({
            "rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4": true,
            [color.bg[600]]: isPrimary,
            "bg-gray-800": isDark,
          })}
        >
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              {title && (
                <SectionTitleElement
                  text={title}
                  isPrimary={isPrimary}
                  isDark={isDark}
                  color={color}
                  size="3xl"
                />
              )}

              <DescriptionElement
                text={description}
                isDark={isDark}
                isPrimary={isPrimary}
                color={color}
              />

              <CtaButtonsElement
                primary={primaryCta}
                secondary={secondaryCta}
                start={true}
                isPrimary={true}
                isDark={true}
                color={color}
              />
            </div>
          </div>
          {imageAsset && (
            <Img
              fluid={imageAsset.src.childImageSharp.fluid}
              className="relative shadow-xl pb-3/5 -mt-6 md:pb-1/2 rounded-md transform translate-x-6 translate-y-6 sm:translate-x-16 lg:translate-y-20"
              style={{ width: "100%", height: "100%" }}
              imgStyle={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                width: "100%",
                height: "100%",
                transform: "translate(20px 20px)",
                objectFit: "cover",
                objectPosition: "0 0",
                position: "absolute",
              }}
              alt={imageAsset.alt}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export { BlockCtaImage as default };
