import React from "react";
import { FORM_MAGIC_ITEMS_MAP } from "./form-magic.items";

function transformToKeyedItems(items) {
    if (!Array.isArray(items)) {
        return null;
    }

    return items.reduce((a, b) => {
        a[b.key] = b;
        return a;
    }, {});
}

function FormMagicLayoutCol({ col, items, error, isPrimeError }) {
    const item = items[col];

    if (!item) {
        return null;
    }

    const ItemComponent = FORM_MAGIC_ITEMS_MAP[item.type];

    return (
        <div className="flex-grow">
            <ItemComponent item={item} error={error} isPrimeError={isPrimeError} />
        </div>
    );
}

function FormMagicLayoutRow({ row, items, errors, primeError }) {
    return (
        <div className="flex space-x-4">
            {Array.isArray(row) &&
                row.map((col, idx) => (
                    <FormMagicLayoutCol key={idx} col={col} items={items} error={errors?.[col]} isPrimeError={col === primeError} />
                ))}
            {typeof row === "string" && (
                <FormMagicLayoutCol col={row} items={items} error={errors?.[row]} isPrimeError={row === primeError} />
            )}
        </div>
    );
}

function FormMagicLayout({ layout, items, errors }) {
    if (!Array.isArray(layout)) {
        return null;
    }

    const primeErrorRow = ((errors && layout.find((row) => {
        if (typeof row === "string") {
            return !!errors[row];
        }

        if (Array.isArray(row)) {
            return row.some((col) => !!errors[col]);
        }
    }))) || null;

    const primeError = typeof primeErrorRow === "string"
        ? primeErrorRow
        : Array.isArray(primeErrorRow) ? primeErrorRow.find((col) => !!errors[col]) : null;

    return (
        <div className="flex flex-col space-y-4">
            {layout.map((row, idx) => (
                <FormMagicLayoutRow key={idx} row={row} items={items} errors={errors} primeError={primeError} />
            ))}
        </div>
    );
}

export function FormMagicBuilder({ layout, items, errors }) {
    if (!(Array.isArray(items) && Array.isArray(layout))) {
        return null;
    }

    const keyedItems = transformToKeyedItems(items);

    if (!keyedItems) {
        return null;
    }

    return (
        <div>
            <FormMagicLayout layout={layout} items={keyedItems} errors={errors} />
        </div>
    );
}
