import React from "react";
import { DescriptionElement } from "../../elements/description.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { CtaTitleElement } from "./elements/cta.title.element";

export function BlockCta({ data }) {
  const {
    title,
    description,
    cta,
    left = false,
    center = false,
    isDark = false,
    color,
  } = data;
  const { primary: primaryCta, secondary: secondaryCta } = cta;

  const baseClassNames =
    "max-w-2xl py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20";
  const basePositionClassNames = center ? "mx-auto text-center" : "";

  const mainClassNames = [baseClassNames, basePositionClassNames].join(" ");

  return (
    <div>
      <div className={mainClassNames}>
        <CtaTitleElement
          title={title}
          isDark={isDark}
          center={center}
          left={left}
          color={color}
        />

        <DescriptionElement
          text={description}
          isInverted={isDark}
          center={center}
          left={left}
          color={color}
        />

        <CtaButtonsElement
          primary={primaryCta}
          secondary={secondaryCta}
          isDark={isDark}
          center={center}
          left={left}
          color={color}
        />
      </div>
    </div>
  );
}

export { BlockCta as default };
