import React from "react";
import { IconElement } from "../icon.element";
import { motion } from "framer-motion";
import classNames from "classnames";

const variants = {
  active: {
    opacity: [1, 1, 1],
    width: ["0%", "100%", "100%"],
    height: ["20%", "20%", "100%"],
  },
  hold: {
    opacity: [1, 1, 1],
    width: ["100%", "100%", "0%"],
    height: ["100%", "20%", "20%"],
  },
};

export function SceneIconElement({ icon, isActiveScene = false }) {
  if (typeof icon !== "string") {
    return null;
  }

  return (
    <div className="mb-6 flex items-end justify-start w-16 h-16 text-gray-700">
      <motion.div
        animate={isActiveScene ? "active" : "hold"}
        variants={variants}
        transition={{ duration: 0.5, delay: 0.1 }}
        className="flex items-center justify-center bg-white rounded-lg shadow-sm overflow-hidden"
      >
        <div
          className={classNames(
            "flex items-center justify-center text-3xl transition",
            {
              "duration-500 delay-500 opacity-100": isActiveScene,
              "duration-200 delay-0 opacity-0": !isActiveScene,
            }
          )}
        >
          <IconElement icon={icon} />
        </div>
      </motion.div>
    </div>
  );
}
