import React from "react";
import { DescriptionElement } from "../../../elements/description.element";

export function HeroLeadElement({
  text,
  isDark = false,
  isPrimary = false,
  color,
}) {
  const baseClassNames =
    "mt-3 font-sans font-light text-base sm:mt-8 sm:text-lg lg:text-lg xl:text-xl";
  const colorClassNames = isDark
    ? "text-gray-200"
    : isPrimary
    ? color.text[100]
    : "text-gray-800";

  const classNames = [baseClassNames, colorClassNames].join(" ");

  return (
    <DescriptionElement
      className={classNames}
      text={text}
      isDark={isDark}
      isPrimary={isPrimary}
      color={color}
    />
  );
}
