import React from "react";
import { IconElement } from "../icon.element";

function LandingFeatureBoxFeature({ feature, color }) {
  const { icon, title, description } = feature || {};

  return (
    <div className="pt-6">
      <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
        <div className="-mt-6">
          {icon && (
            <div>
              <span
                className={`inline-flex text-xl text-white items-center justify-center p-3 ${color.bg["500"]} rounded-md shadow-lg`}
              >
                <IconElement icon={icon} />
              </span>
            </div>
          )}

          {title && (
            <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
              {title}
            </h3>
          )}

          {description && (
            <p className="mt-5 text-base text-gray-700 whitespace-pre-line">
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default function LandingFeatureBoxElement({ data, color }) {
  const { title, subtitle, description, features } = data || {};

  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        {subtitle && (
          <h2
            className={`text-base font-semibold tracking-wider ${color.text["600"]} uppercase`}
          >
            {subtitle}
          </h2>
        )}

        {title && (
          <h2 className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            {title}
          </h2>
        )}

        {description && (
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-700 whitespace-pre-line">
            {description}
          </p>
        )}

        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 lg:gap-12 sm:grid-cols-2">
            {features.map(feature => (
              <LandingFeatureBoxFeature
                key={feature.title}
                feature={feature}
                color={color}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
