import React from "react";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";
import { markdownRenderers } from "../../helpers/markdown-renderers.helper";

export function MarkdownElement({
  children,
  color,
  isDark = false,
  isPrimary = false,
  proseMode = true,
  className,
}) {
  return (
    <div className={className}>
      <ReactMarkdown
        renderers={markdownRenderers({ color, isDark, isPrimary, proseMode })}
        className={classNames({
          prose: proseMode,
        })}
      >
        {children}
      </ReactMarkdown>
    </div>
  );
}
