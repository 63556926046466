import React, { Component } from "react";
import { FORM_PROVIDER_COMPONENT_MAP } from "./form-provider-component.map";

export class BlockFormEmbeddedEmail extends Component {
  getProviderComponent() {
    return FORM_PROVIDER_COMPONENT_MAP[this.props?.data?.provider] || null;
  }

  render() {
    const data = this.props?.data || {};
    const color = this.props?.color || {};

    if (data && typeof data !== "object") {
      return null;
    }

    const { config, provider } = data;
    const ProviderComponent = this.getProviderComponent();
    const providerBaseConfig =
      this.props?.siteConfig?.formProviderSettings?.[provider] || {};

    return (
      ProviderComponent && (
        <ProviderComponent
          config={config}
          baseConfig={providerBaseConfig}
          data={data}
          color={color}
        />
      )
    );
  }
}
