import React from "react";

export function EmbeddedLinkElement({ url }) {
  const key = Math.random().toString();

  return (
    <div
      className="relative h-auto max-w-full w-96 rounded-lg shadow-2xl"
      style={{ paddingBottom: "calc(16 / 16 * 100%)", paddingTop: "0" }}
    >
      <iframe className="absolute w-full h-full" title={key} src={url}></iframe>
    </div>
  );
}
