import React, { Fragment } from "react";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { ImageAssetElement } from "../../elements/image-asset.element";
import { NavbarTopCenteredElement } from "../../elements/navbar/navbar.top.centered.element";
import { TitleLabelElement } from "../../elements/title-label.element";
import { HeroLeadElement } from "./elements/hero.lead.element";
import { HeroTitleElement } from "./elements/hero.title.element";

export function BlockHeroSplitImageAngled({ data, assets, color }) {
  const {
    title,
    lead,
    image,
    subtitle,
    navigation = {},
    isDark = false,
    isPrimary = false,
    cta = {},
  } = data;

  const { primary: primaryCta = null, secondary: secondaryCta = null } = cta;

  return (
    <Fragment>
      <div className="relative overflow-hidden">
        <div className="max-w-screen-xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <NavbarTopCenteredElement
              data={navigation}
              isDark={isDark}
              isPrimary={isPrimary}
              assets={assets}
              color={color}
            />

            <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                {subtitle && (
                  <TitleLabelElement
                    text={subtitle}
                    isDark={isDark}
                    isPrimary={isPrimary}
                    color={color}
                  />
                )}

                <HeroTitleElement
                  title={title}
                  isDark={isDark}
                  isPrimary={isPrimary}
                  color={color}
                />

                <div className="md:max-w-xl">
                  <HeroLeadElement
                    text={lead}
                    isDark={isDark}
                    isPrimary={isPrimary}
                    color={color}
                  />
                </div>

                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  {cta && (
                    <div className="flex items-center justify-start sm:justify-center lg:justify-start">
                      <CtaButtonsElement
                        primary={primaryCta}
                        secondary={secondaryCta}
                        centered={false}
                        isDark={isDark}
                        size="lg"
                        isPrimary={isPrimary}
                        color={color}
                      />
                    </div>
                  )}
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <ImageAssetElement
            className="w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
            assets={assets}
            assetKey={image}
            isFluid={true}
            objectFit="cover"
          />
        </div>
      </div>
    </Fragment>
  );
}

export { BlockHeroSplitImageAngled as default };
