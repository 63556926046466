import React, { Fragment } from "react";
// import { FeatureTitleElement } from "./elements/feature.title.element";
// import { FeatureSubtitleElement } from "./elements/feature.subtitle.element";
import { FeatureDescriptionElement } from "./../feature/elements/feature.description.element";
import { IconElement } from "../../elements/icon.element";
// import { classNames } from "../../../helpers/class-names.helper";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";

export function BlockWebinarPromo({ data = {}, color }) {
  const {
    date,
    duration = null,
    alternativeDates,
    title,
    subtitle,
    cta = {},
    description,
    features,
    isDark = false,
    isPrimary = false,
  } = data;
  const { primary: primaryCta, secondary: secondaryCta } = cta;

  return (
    <div className="relative bg-white">
      <div className="absolute inset-0" aria-hidden="true">
        <div
          className={`absolute inset-y-0 right-0 w-1/2 ${color.bg[600]}`}
        ></div>
      </div>
      <div className="relative max-w-7xl mx-auto lg:px-8 lg:grid lg:grid-cols-2">
        <div className="bg-white py-16 px-4 sm:py-24 sm:px-6 lg:px-0 lg:pr-8">
          <div className="max-w-lg mx-auto lg:mx-0">
            {subtitle && (
              <h2
                className={`text-base font-semibold tracking-wide ${color.text[600]} uppercase`}
              >
                {subtitle}
              </h2>
            )}

            {title && (
              <p className="mt-2 text-2xl font-extrabold text-gray-900 sm:text-3xl">
                {title}
              </p>
            )}

            <FeatureDescriptionElement
              text={description}
              isDark={isDark}
              isPrimary={isPrimary}
              color={color}
            />

            {Array.isArray(features) && (
              <dl className="mt-12 space-y-10">
                {features.map(({ title, description, icon }) => {
                  return (
                    <div className="flex" key={title}>
                      <div
                        className={`flex-shrink-0 h-12 w-12 text-xl flex items-center justify-center ${color.bg[600]} rounded-md text-white`}
                      >
                        <IconElement icon={icon} />
                      </div>
                      <div className="ml-4">
                        {title && (
                          <dt className="text-lg leading-6 font-medium text-gray-900">
                            {title}
                          </dt>
                        )}
                        {description && (
                          <dd className="mt-2 text-base text-gray-700">
                            {description}
                          </dd>
                        )}
                      </div>
                    </div>
                  );
                })}
              </dl>
            )}
          </div>
        </div>
        <div
          className={`${color.bg[600]} py-16 px-4 sm:py-24 sm:px-6 lg:bg-none lg:px-0 lg:pl-8 lg:flex lg:items-center lg:justify-end`}
        >
          <div className="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
            <div>
              <h2 className="sr-only">Price</h2>
              <p className="relative grid grid-cols-1 align-bottom">
                <span className="flex flex-col text-center">
                  <span
                    className={`mb-2 text-base font-medium ${color.text[200]}`}
                  >
                    {Intl.DateTimeFormat("de", {
                      timezone: "Europe/Zurich",
                      hour12: false,
                      weekday: "long",
                    }).format(new Date(date))}
                  </span>
                  <span className="text-4xl font-extrabold text-white tracking-tight">
                    {Intl.DateTimeFormat("de", {
                      timezone: "Europe/Zurich",
                      hour12: false,
                      year: "numeric",
                      day: "numeric",
                      month: "long",
                    }).format(new Date(date))}
                    <br />
                    {Intl.DateTimeFormat("de", {
                      timezone: "Europe/Zurich",
                      hour12: false,
                      hour: "numeric",
                      minute: "2-digit",
                    }).format(new Date(date))}
                    {duration && (
                      <Fragment>
                        &nbsp;-&nbsp;
                        {Intl.DateTimeFormat("de", {
                          timezone: "Europe/Zurich",
                          hour12: false,
                          hour: "numeric",
                          minute: "2-digit",
                        }).format(
                          new Date(
                            new Date(date).getTime() + duration * 60 * 1000
                          )
                        )}
                      </Fragment>
                    )}
                    &nbsp;Uhr
                  </span>
                </span>
              </p>
            </div>
            <ul
              className={`${color.bg[700]} bg-opacity-50 rounded sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col`}
            >
              <li className="py-4 px-4 flex items-center space-x-3 text-sm text-white">
                <svg
                  className={`h-6 w-6 ${color.text[300]}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>Teilnahme ist kostenlos</span>
              </li>
              <li
                className={`border-t ${color.border[500]} border-opacity-25 py-4 px-4 flex items-center space-x-3 text-sm text-white`}
              >
                <svg
                  className={`h-6 w-6 ${color.text[300]}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>Platzzahl ist beschränkt</span>
              </li>
              <li
                className={`border-t ${color.border[500]} border-opacity-25 py-4 px-4 flex items-center space-x-3 text-sm text-white sm:border-t-0 sm:border-l`}
              >
                <svg
                  className={`h-6 w-6 ${color.text[300]}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>Dauer ca. 30 Minuten</span>
              </li>
              <li
                className={`border-t ${color.border[500]} border-opacity-25 py-4 px-4 flex items-center space-x-3 text-sm text-white sm:border-t-0 sm:border-l`}
              >
                <svg
                  className={`h-6 w-6 ${color.text[300]}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>Desktop oder Smartphone</span>
              </li>
            </ul>

            <CtaButtonsElement
              primary={primaryCta}
              secondary={secondaryCta}
              center={true}
              isDark={true}
              size="lg"
              color={color}
            />

            {Array.isArray(alternativeDates) && (
              <Fragment>
                <span
                  className={`block text-center text-base font-regular ${color.text[200]}`}
                >
                  Alternativdaten
                </span>
                <ul>
                  {alternativeDates.map(
                    ({ date: altDate, path, fullyBooked }) => {
                      if (date === altDate) {
                        return null;
                      }

                      const dateFormattedDate = Intl.DateTimeFormat("de", {
                        timezone: "Europe/Zurich",
                        hour12: false,
                        weekday: "long",
                        year: "numeric",
                        day: "numeric",
                        month: "long",
                      }).format(new Date(altDate));
                      const dateFormattedTime = Intl.DateTimeFormat("de", {
                        timezone: "Europe/Zurich",
                        hour12: false,
                        hour: "numeric",
                        minute: "2-digit",
                      }).format(new Date(altDate));
                      return (
                        <a
                          href={path}
                          key={date}
                          tabIndex="-1"
                          className={`group relative ${
                            fullyBooked ? "pointer-events-none" : null
                          } rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-black-500`}
                        >
                          <div
                            className={`rounded-lg border mb-2 ${
                              fullyBooked
                                ? color.border[500]
                                : color.border[600]
                            } ${
                              fullyBooked ? color.bg[600] : color.bg[500]
                            } px-6 py-4 hover:${
                              color.border[400]
                            } sm:flex sm:justify-between`}
                          >
                            <div className="flex items-center">
                              <div className="text-sm">
                                <p
                                  className={`font-medium ${color.text[200]} group-hover:${color.text[100]}`}
                                >
                                  {dateFormattedDate}
                                </p>
                              </div>
                              <div className="ml-2">
                                <p
                                  className={`${color.text[300]} font-regular text-sm sm:inline`}
                                >
                                  <time>{dateFormattedTime}&nbsp;Uhr</time>
                                </p>
                              </div>
                            </div>
                            <div className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                              <div className="font-medium">
                                {fullyBooked && (
                                  <span
                                    className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${color.bg[500]} ${color.text[200]}`}
                                  >
                                    Ausgebucht!
                                  </span>
                                )}
                                {!fullyBooked && (
                                  <span
                                    className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${color.bg[600]} ${color.text[200]}`}
                                  >
                                    Freie Plätze
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    }
                  )}
                </ul>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  //   return (
  // <div className="relative bg-white">
  //   <div className="absolute inset-0" aria-hidden="true">
  //     <div className="absolute inset-y-0 right-0 w-1/2 bg-indigo-700"></div>
  //   </div>
  //   <div className="relative max-w-7xl mx-auto lg:px-8 lg:grid lg:grid-cols-2">
  //     <div className="bg-white py-16 px-4 sm:py-24 sm:px-6 lg:px-0 lg:pr-8">
  //       <div className="max-w-lg mx-auto lg:mx-0">
  //           {/* { subtitle && (
  //         <h2 className="text-base font-semibold tracking-wide text-indigo-600 uppercase">
  //           {subtitle}
  //         </h2>
  //           )} */}

  //                     <FeatureSubtitleElement
  //             title={subtitle}
  //             isDark={isDark}
  //             isPrimary={isPrimary}
  //             color={color}
  //           />
  //           <FeatureTitleElement
  //             title={title}
  //             isDark={isDark}
  //             isPrimary={isPrimary}
  //             color={color}
  //           />
  //           <FeatureDescriptionElement
  //             text={description}
  //             isDark={isDark}
  //             isPrimary={isPrimary}
  //             color={color}
  //           />

  //         {/* <p className="mt-2 text-2xl font-extrabold text-gray-900 sm:text-3xl">
  //             Everything you need to talk with your customers
  //         </p> */}
  // {Array.isArray(features) && (
  //                   <dl className="mt-12 space-y-10">

  //             {features.map(({ title, description, icon }) => {
  //               return (
  //           <div className="flex" key={title}>
  //             <div className="flex-shrink-0 h-12 w-12 flex items-center justify-center bg-indigo-500 rounded-md">
  //                   {icon && (
  //                     <span
  //                       className={classNames([
  //                         "flex-shrink-0 h-6 w-6 text-lg",
  //                         isPrimary && color.text[200],
  //                         isDark && "text-gray-200",
  //                         !isPrimary && !isDark && color.text[500],
  //                       ])}
  //                     >
  //                       <IconElement icon={icon} />
  //                     </span>
  //                   )}
  //             </div>
  //             <div className="ml-4">
  //                     {title && (
  //                       <dt
  //                         className={classNames([
  //                           "text-lg leading-6 font-medium ",
  //                           isPrimary && "text-white",
  //                           isDark && "text-gray-100",
  //                           !isPrimary && !isDark && "text-gray-900",
  //                         ])}
  //                       >
  //                         {title}
  //                       </dt>
  //                     )}

  //                     <dd className="flex space-x-3 lg:py-0 lg:pb-4">
  //                       {description && (
  //                         <span
  //                           className={classNames([
  //                             "mt-2 text-base text-gray-500",
  //                             isPrimary && color.text[200],
  //                             isDark && "text-gray-400",
  //                             !isPrimary && !isDark && "text-gray-700",
  //                           ])}
  //                         >
  //                           {description}
  //                         </span>
  //                       )}
  //                     </dd>
  //             </div>
  //             </div>
  //             })}
  //             </dl>
  //         )}
  //       </div>
  //     </div>
  //     <div className="bg-indigo-700 py-16 px-4 sm:py-24 sm:px-6 lg:bg-none lg:px-0 lg:pl-8 lg:flex lg:items-center lg:justify-end">
  //       <div className="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
  //         <div>
  //           <h2 className="sr-only">Price</h2>
  //           <p className="relative grid grid-cols-2">
  //             <span className="flex flex-col text-center">
  //               <span className="text-5xl font-extrabold text-white tracking-tight">$99</span>
  //               <span className="mt-2 text-base font-medium text-indigo-200">Setup fee</span>
  //               <span className="sr-only">plus</span>
  //             </span>
  //             <span className="pointer-events-none absolute h-12 w-full flex items-center justify-center" aria-hidden="true">
  //               <svg className="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
  //                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
  //               </svg>
  //             </span>
  //             <span>
  //               <span className="flex flex-col text-center">
  //                 <span className="text-5xl font-extrabold text-white tracking-tight">$4</span>
  //                 <span className="mt-2 text-base font-medium text-indigo-200">Per month</span>
  //               </span>
  //             </span>
  //           </p>
  //         </div>

  //         <ul className="bg-indigo-800 bg-opacity-50 rounded sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col">
  //           <li className="py-4 px-4 flex items-center space-x-3 text-sm text-white">
  //             <svg className="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
  //               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
  //             </svg>
  //             <span>Unlimited Projects</span>
  //           </li>
  //           <li className="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-sm text-white">
  //             <svg className="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
  //               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
  //             </svg>
  //             <span>Unlimited storage</span>
  //           </li>
  //           <li className="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-sm text-white">
  //             <svg className="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
  //               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
  //             </svg>
  //             <span>Cancel anytime</span>
  //           </li>
  //           <li className="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-sm text-white sm:border-t-0 sm:border-l">
  //             <svg className="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
  //               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
  //             </svg>
  //             <span>No per user fees</span>
  //           </li>
  //           <li className="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-sm text-white sm:border-l">
  //             <svg className="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
  //               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
  //             </svg>
  //             <span>24/7 support</span>
  //           </li>
  //           <li className="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-sm text-white sm:border-l">
  //             <svg className="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
  //               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
  //             </svg>
  //             <span>Cancel anytime</span>
  //           </li>
  //         </ul>

  //         <CtaButtonsElement
  //           primary={primaryCta}
  //           secondary={secondaryCta}
  //           center={true}
  //           isDark={isDark || isPrimary}
  //           color={color}
  //         />

  //         <a href="#" className="bg-white border border-transparent rounded-md w-full px-8 py-4 flex items-center justify-center text-lg leading-6 font-medium text-indigo-600 hover:bg-indigo-50 md:px-10">
  //           Get started today
  //         </a>
  //         <a href="#" className="block text-center text-base font-medium text-indigo-200 hover:text-white">
  //           Try Workflow Lite for free
  //         </a>
  //       </div>
  //     </div>
  //     </div>
  //     </div>
  //   );
}
