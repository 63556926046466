import React from "react";
import { motion } from "framer-motion";

const variants = {
  active: {
    x: 0,
    opacity: 1,
    duration: 1000,
  },
  hold: {
    x: "-5px",
    opacity: 0,
  },
};

export function SceneSubtitleElement({ subtitle, isActiveScene }) {
  if (typeof subtitle !== "string") {
    return null;
  }

  return (
    <motion.div
      animate={isActiveScene ? "active" : "hold"}
      variants={variants}
      transition={{ duration: 0.4 }}
      className="text-sm font-semibold tracking-wider text-gray-700 uppercase my-3"
    >
      {subtitle}
    </motion.div>
  );
}
