import React from "react";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";

import { IconElement } from "../../elements/icon.element";

function FeatureItem({ icon, title, description, number }) {
  return (
    <div key={title} className="pt-6">
      <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
        <div className="-mt-6">
          <div>
            <span
              className={`inline-flex items-center justify-center p-3 bg-gray-800 rounded-md shadow-lg`}
            >
              <span className="inline-block text-white font-extrabold text-2xl">
                {icon && <IconElement icon={icon} />}
                {number && <span className="px-2">{number}</span>}
              </span>
              {/* <feature.icon className="h-6 w-6 text-white" aria-hidden="true" /> */}
            </span>
          </div>

          {title && (
            <strong className="block mt-8 text-lg font-bold text-gray-900">
              {title}
            </strong>
          )}

          {description && (
            <p className="mt-3 text-base text-gray-700 whitespace-pre-line prose prose-base">
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export function BlockFeatureGridIconsOffset({ data, color }) {
  const { subtitle, title, description, features, cta, isPrimary, isDark } =
    data || {};
  const { primary: primaryCta, secondary: secondaryCta } = cta || {};

  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        {subtitle && (
          <h2
            className={`text-base font-semibold tracking-wider ${color.text[600]} uppercase`}
          >
            {subtitle}
          </h2>
        )}

        {title && (
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            {title}
          </p>
        )}

        {description && (
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-700 whitespace-pre-line">
            {description}
          </p>
        )}

        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map(f => (
              <FeatureItem {...f} key={f.title} />
            ))}
          </div>
        </div>
      </div>

      <CtaButtonsElement
        primary={primaryCta}
        secondary={secondaryCta}
        center={true}
        isDark={isDark || isPrimary}
        color={color}
      />
    </div>
  );
}

export { BlockFeatureGridIconsOffset as default };
