import React from "react";
import { ImageAssetElement } from "../../elements/image-asset.element";
import { VideoAssetElement } from "../../elements/video-asset.element";
import { SceneIconElement } from "../../elements/scene/scene-icon.element";
import { SceneSubtitleElement } from "../../elements/scene/scene-subtitle.element";
import { SceneTitleElement } from "../../elements/scene/scene-title.element";
import { SceneDescriptionElement } from "../../elements/scene/scene-description.element";
import { useInView } from "react-intersection-observer";

export function BlockSceneMediaRight({ data, assets }) {
  const { icon, subtitle, title, description, image, video, poster } =
    data || {};

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div className="mx-auto max-w-screen-xl w-full" ref={ref}>
      <div className="flex justify-between items-center flex-wrap relative">
        <div className="absolute inset-0 bg-gray-50 z-0"></div>
        <div className="w-full md:w-3/6 z-10">
          <div className="p-8 md:pl-12">
            {icon && <SceneIconElement icon={icon} isActiveScene={inView} />}
            {subtitle && (
              <SceneSubtitleElement
                subtitle={subtitle}
                isActiveScene={inView}
              />
            )}
            {title && (
              <SceneTitleElement title={title} isActiveScene={inView} />
            )}
            {description && (
              <SceneDescriptionElement
                description={description}
                isActiveScene={inView}
              />
            )}
          </div>
        </div>
        <div className="w-full md:w-3/6 z-10">
          <div className="m-8 rounded-lg">
            <div className="xshadow-lg rounded-lg">
              <div className="rounded-lg overflow-hidden bg-white">
                {image && (
                  <ImageAssetElement
                    isFixed={true}
                    assetKey={image}
                    assets={assets}
                  />
                )}
                {video && (
                  <VideoAssetElement
                    video={video}
                    poster={poster}
                    assets={assets}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { BlockSceneMediaRight as default };
