import React, { Component } from "react";

export class FormSharpspringNativeElement extends Component {
  constructor(props) {
    super(props);

    this.submitForm = this.submitForm.bind(this);
    this.postFormSubmitCallback = this.postFormSubmitCallback.bind(this);
  }

  componentDidMount() {
    this.initializeNativeForm();
  }

  getScriptSrc() {
    return this.props.baseConfig?.scriptSrc;
  }

  isScriptEmbedded() {
    const scriptSrc = this.getScriptSrc();
    return !!document.querySelector(`script[src="${scriptSrc}"]`);
  }

  getFormId() {
    return this.props?.config?.formId;
  }

  getBaseURI() {
    return this.props?.baseConfig?.postbackURI;
  }

  getActionURI() {
    return this.getBaseURI() + this.getFormId();
  }

  initializeNativeForm() {
    const formId = this.getFormId();
    window.__ss_noform = window.__ss_noform || [];
    window.__ss_noform.push(["baseURI", this.getBaseURI()]);
    // window.__ss_noform.push(["endpoint", endpoint]); // -> https://help.sharpspring.com/hc/en-us/articles/115001065908-Tracking-Multiple-Native-Forms-on-a-Single-Page#h_951716390551525967719738
    window.__ss_noform.push(["form", formId, formId]);
    window.__ss_noform.push(["submitType", "manual"]);

    if (!this.isScriptEmbedded()) {
      const scriptSrc = this.getScriptSrc();
      const scriptTag = document.createElement("script");
      scriptTag.setAttribute("async", "async");
      scriptTag.setAttribute("src", scriptSrc);
      document.body.appendChild(scriptTag);
    }
  }

  postFormSubmitCallback(...anything) {
    // console.log("PostSubmit", anything);
  }

  submitForm(evt) {
    evt.preventDefault();
    window.__ss_noform.push([
      "submit",
      this.postFormSubmitCallback,
      this.getFormId(),
    ]);
  }

  render() {
    return (
      <form
        id={this.getFormId()}
        method="post"
        action={this.getActionURI()}
        onSubmit={this.submitForm}
      >
        <input type="email" name="dada" placeholder="email" required={true} />
        <input type="text" name="Test_Name" placeholder="Name" />
        <input type="text" name="Test_Firstname" placeholder="firstname" />
        <input type="date" name="Test_Date" placeholder="Date" />
        <textarea
          name="This_is_a_textarea"
          placeholder="any text in textarea"
        ></textarea>

        <button type="submit">I am a submit button</button>
        <button type="reset">I am a reset button</button>
      </form>
    );
  }
}
