import React, { Fragment } from "react";
import { classNames } from "../../../helpers/class-names.helper";
import { FeatureSubtitleElement } from "./elements/feature.subtitle.element";
import { FeatureTitleElement } from "./elements/feature.title.element";
import { FeatureDescriptionElement } from "./elements/feature.description.element";
import { IconElement } from "../../elements/icon.element";
import { ImageAssetElement } from "../../elements/image-asset.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { EmbeddedLinkElement } from "../../elements/embedded-link.element";
import { MarkdownElement } from "../../elements/markdown.element";
import { BlockFormNested } from "../form/block.form.nested";
import { SectionTitleElement } from "../../elements/section-title.element";
import { VideoElement } from "../../elements/video.element";
import { ChartElement } from "../../elements/chart.element";

export function BlockFeatureSplit({ data = {}, assets, color, siteConfig }) {
  const {
    title,
    subtitle,
    cta = {},
    description,
    features,
    isDark = false,
    startsRight = false,
    isPrimary = false,
  } = data;
  const { primary: primaryCta, secondary: secondaryCta } = cta;

  return (
    <div className="py-12 overflow-hidden lg:py-16">
      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className={classNames([
                  isPrimary && color.text[700],
                  isDark && "text-gray-700",
                  !isPrimary && !isDark && "text-gray-100",
                ])}
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative max-w-3xl mx-auto lg:text-center">
          <FeatureSubtitleElement
            title={subtitle}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />
          <FeatureTitleElement
            title={title}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />
          <FeatureDescriptionElement
            text={description}
            isDark={isDark}
            isPrimary={isPrimary}
            color={color}
          />
        </div>

        {Array.isArray(features) && (
          <Fragment>
            {features.map(
              (
                {
                  title,
                  description,
                  features,
                  image,
                  embeddedLink,
                  video,
                  chart,
                  form,
                  isBoxed = true,
                },
                idx
              ) => {
                const key = title || idx + 1;
                return (
                  <div
                    className={classNames([
                      "realtive mt-12 sm:mt-16 lg:mt-24 lg:grid lg:gap-8 lg:items-center lg:grid-cols-2",
                      !(idx % 2) && null,
                      idx % 2 && !startsRight && "lg:grid-flow-row-dense",
                      idx % 2 === 0 && startsRight && "lg:grid-flow-row-dense",
                    ])}
                    key={key}
                  >
                    <div
                      className={classNames([
                        "z-20 max-w-lg",
                        !(idx % 2) && !startsRight && "relative",
                        idx % 2 && !startsRight && "lg:col-start-2",
                        !(idx % 2) && startsRight && "lg:col-start-2",
                        idx % 2 && startsRight && "relative",
                      ])}
                    >
                      <SectionTitleElement
                        size="3xl"
                        isPrimary={isPrimary}
                        isDark={isDark}
                        text={title}
                        color={color}
                      />

                      <div
                        className={classNames([
                          "mt-3 text-lg leading-7",
                          isPrimary && color.text[200],
                          isDark && "text-gray-400",
                          !isPrimary && !isDark && "text-gray-700",
                        ])}
                      >
                        <MarkdownElement
                          isDark={isDark}
                          isPrimary={isPrimary}
                          size="lg"
                          color={color}
                          proseMode={false}
                        >
                          {description}
                        </MarkdownElement>
                      </div>

                      {Array.isArray(features) && (
                        <ul>
                          {features.map(
                            ({ title, description, icon, step }) => {
                              const key = title || Math.random().toString();
                              return (
                                <li className="mt-10" key={key}>
                                  <div className="flex">
                                    {(step || icon) && (
                                      <div className="flex-shrink-0">
                                        <div
                                          className={classNames([
                                            "flex items-center justify-center w-6 h-6 sm:h-12 sm:w-12 rounded-md",
                                            isPrimary &&
                                              isBoxed &&
                                              `${color.bg[600]} text-white`,
                                            isDark &&
                                              isBoxed &&
                                              "bg-gray-600 text-white",
                                            !isPrimary &&
                                              !isDark &&
                                              isBoxed &&
                                              `${color.text[500]}`,
                                            isPrimary &&
                                              !isBoxed &&
                                              `${color.text[600]}`,
                                            isDark &&
                                              !isBoxed &&
                                              "text-gray-600",
                                            !isPrimary &&
                                              !isDark &&
                                              !isBoxed &&
                                              `${color.text[500]}`,
                                          ])}
                                        >
                                          {icon && (
                                            <span className="flex justify-center items-center text-center w-2 h-2 sm:h-6 sm:w-6 text-xs sm:text-lg">
                                              <IconElement icon={icon} />
                                            </span>
                                          )}
                                          {step && (
                                            <span className="flex justify-center items-center text-center w-2 h-2 sm:h-6 sm:w-6 text-xs sm:text-xl font-bold">
                                              {step}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    <div className="ml-4">
                                      <h4
                                        className={classNames([
                                          "text-lg leading-6  font-medium font-sans",
                                          isPrimary && `${color.text[100]}`,
                                          isDark && "text-gray-100",
                                          !isPrimary &&
                                            !isDark &&
                                            "text-gray-900",
                                        ])}
                                      >
                                        {title}
                                      </h4>
                                      <div
                                        className={classNames([
                                          "mt-2 text-base leading-6",
                                          isPrimary && color.text[200],
                                          isDark && "text-gray-400",
                                          !isPrimary &&
                                            !isDark &&
                                            "text-gray-700",
                                        ])}
                                      >
                                        <MarkdownElement
                                          isDark={isDark}
                                          isPrimary={isPrimary}
                                          size="md"
                                          color={color}
                                          proseMode={false}
                                        >
                                          {description}
                                        </MarkdownElement>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      )}
                    </div>
                    <div
                      className={classNames([
                        !(idx % 2) &&
                          !startsRight &&
                          "relative mt-10 -mx-4 lg:mt-0 justify-center flex",
                        idx % 2 &&
                          !startsRight &&
                          "mt-10 -mx-4 relative lg:mt-0 lg:col-start-1 justify-center flex",
                        !(idx % 2) &&
                          startsRight &&
                          "relative mt-10 -mx-4 lg:mt-0 justify-center flex",
                        idx % 2 &&
                          startsRight &&
                          "mt-10 -mx-4 relative lg:mt-0 lg:col-start-1 justify-center flex",
                      ])}
                    >
                      <svg
                        className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 z-0 md:block hidden"
                        width="784"
                        height="404"
                        fill="none"
                        viewBox="0 0 784 404"
                      >
                        <defs>
                          <pattern
                            id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                            x="0"
                            y="0"
                            width="20"
                            height="20"
                            patternUnits="userSpaceOnUse"
                          >
                            <rect
                              x="0"
                              y="0"
                              width="4"
                              height="4"
                              className={classNames([
                                isPrimary && color.text[800],
                                isDark && "text-gray-700",
                                !isPrimary && !isDark && "text-gray-100",
                              ])}
                              fill="currentColor"
                            />
                          </pattern>
                        </defs>
                        <rect
                          width="784"
                          height="404"
                          fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                        />
                      </svg>

                      {embeddedLink && (
                        <EmbeddedLinkElement url={embeddedLink} />
                      )}

                      {video && (
                        <VideoElement
                          data={video}
                          assets={assets}
                          color={color}
                        />
                      )}

                      {form && (
                        <BlockFormNested
                          data={form}
                          assets={assets}
                          color={color}
                          siteConfig={siteConfig}
                        />
                      )}

                      {image && (
                        <ImageAssetElement
                          assets={assets}
                          isFluid={true}
                          assetKey={image}
                          className="rounded lg:max-w-lg lg:mx-0 mx-12"
                        />
                      )}

                      {chart && <ChartElement chart={chart} color={color} />}
                    </div>
                  </div>
                );
              }
            )}
          </Fragment>
        )}
      </div>

      <div className="mt-16 relative z-10">
        <CtaButtonsElement
          primary={primaryCta}
          secondary={secondaryCta}
          center={true}
          isDark={isDark || isPrimary}
          color={color}
        />
      </div>
    </div>
  );
}

export { BlockFeatureSplit as default };
