import { FormSharpspringNativeEmailElement } from "./elements/form-sharpspring-native-email.element";
import { FormSharpspringNativeElement } from "./elements/form-sharpspring-native.element";
import { FormSharpspringElement } from "./elements/form-sharpspring.element";
import { FormMagicElement } from "./elements/form-magic.element";

export const FORM_PROVIDER_COMPONENT_MAP = {
  sharpspring: FormSharpspringElement,
  sharpspringNative: FormSharpspringNativeElement,
  sharpspringNativeEmail: FormSharpspringNativeEmailElement,
  magic: FormMagicElement,
};
