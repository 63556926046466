import React from "react";
import { motion } from "framer-motion";

const variants = {
  active: {
    x: 0,
    opacity: 1,
    duration: 1000,
  },
  hold: {
    x: "-10px",
    opacity: 0,
  },
};

export function SceneTitleElement({ title, isActiveScene }) {
  if (typeof title !== "string") {
    return null;
  }

  return (
    <motion.div
      animate={isActiveScene ? "active" : "hold"}
      variants={variants}
      transition={{ duration: 0.6, delay: isActiveScene && 0.4 }}
      className="block max-w-md font-extrabold font-sans text-2xl sm:text-3xl leading-9 sm:leading-10 text-gray-900"
    >
      {title}
    </motion.div>
  );
}
