import React from "react";
import classNames from "classnames";

export function TitleLabelElement({ text, isPrimary, isDark, color }) {
  return (
    <div
      className={classNames({
        "text-base leading-6 font-semibold uppercase tracking-wider mb-2": true,
        [color.text[600]]: !isPrimary && !isDark,
        [color.text[100]]: isPrimary,
        [color.text[50]]: isDark,
      })}
    >
      {text}
    </div>
  );
}
