import React, { Fragment } from "react";

import { CheckIcon } from "@heroicons/react/outline";
import { CtaButtonsElement } from "../cta.buttons.element";

export default function LandingPricingSingleElement({ data, color }) {
  const { features, cta, offer, featureDescription } = data || {};

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="pb-16 xl:flex xl:items-center xl:justify-between">
          <div>
            {offer && (
              <h2 className="text-4xl font-extrabold sm:text-5xl sm:tracking-tight">
                {offer?.tag && (
                  <span className="text-gray-900">{offer.tag}</span>
                )}{" "}
                {offer?.price && (
                  <span className={`${color.text["600"]}`}>{offer.price}</span>
                )}
              </h2>
            )}
            {offer?.description && (
              <p className="mt-5 text-xl text-gray-700 whitespace-pre-line">
                {" "}
                {offer.description}
              </p>
            )}
          </div>

          {cta && (
            <CtaButtonsElement
              primary={cta?.primary}
              secondary={cta?.secondary}
              color={color}
              size="lg"
            />
          )}
          {/* <a
            href="#"
            className="mt-8 w-full bg-indigo-600 border border-transparent px-5 py-3 inline-flex items-center justify-center text-base font-medium rounded-md text-white hover:bg-indigo-700 sm:mt-10 sm:w-auto xl:mt-0"
          >
            Get started today
          </a> */}
        </div>
        <div className="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
          <div>
            {featureDescription && (
              <Fragment>
                {featureDescription?.subtitle && (
                  <p
                    className={`text-base font-semibold ${color.text["600"]} tracking-wide uppercase`}
                  >
                    {featureDescription.subtitle}
                  </p>
                )}
                {featureDescription?.title && (
                  <h3 className="mt-2 text-3xl font-extrabold text-gray-900">
                    {featureDescription.title}
                  </h3>
                )}
                {featureDescription?.description && (
                  <p className="mt-4 text-lg text-gray-700 whitespace-pre-line">
                    {featureDescription.description}
                  </p>
                )}
              </Fragment>
            )}
          </div>
          <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2">
            <ul className="divide-y divide-gray-200">
              {Array.isArray(features) &&
                features
                  .slice(0, Math.ceil(features.length / 2))
                  .map((feature, featureIdx) =>
                    featureIdx === 0 ? (
                      <li key={feature} className="py-4 flex md:py-0 md:pb-4">
                        <CheckIcon
                          className={`flex-shrink-0 h-6 w-6 ${color.text[600]}`}
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base text-gray-700">
                          {feature}
                        </span>
                      </li>
                    ) : (
                      <li key={feature} className="py-4 flex">
                        <CheckIcon
                          className={`flex-shrink-0 h-6 w-6 ${color.text[600]}`}
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base text-gray-700">
                          {feature}
                        </span>
                      </li>
                    )
                  )}
            </ul>
            <ul className="border-t border-gray-200 divide-y divide-gray-200 md:border-t-0">
              {Array.isArray(features) &&
                features
                  .slice(Math.ceil(features.length / 2))
                  .map((feature, featureIdx) =>
                    featureIdx === 0 ? (
                      <li
                        key={feature}
                        className="py-4 flex md:border-t-0 md:py-0 md:pb-4"
                      >
                        <CheckIcon
                          className={`flex-shrink-0 h-6 w-6 ${color.text[600]}`}
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base text-gray-700">
                          {feature}
                        </span>
                      </li>
                    ) : (
                      <li key={feature} className="py-4 flex">
                        <CheckIcon
                          className={`flex-shrink-0 h-6 w-6 ${color.text[600]}`}
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base text-gray-700">
                          {feature}
                        </span>
                      </li>
                    )
                  )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
